<p class="not-saved-block" *ngIf="hasChanged">Изменения не сохранены</p>

<div *ngIf="IsSelected" class="input-wrap" (click)="$event.stopPropagation()">
    <label class="f-title">Ссылка на видео с YouTube/RUTUBE</label>
    <textarea class="form-input" (ngModelChange)="onChangeLink($event)" [ngModel]="getVideoLink()"
              placeholder="https://www.youtube.com/watch?v= ... или https://www.youtube.com/shorts/...или https://rutube.ru/video/..."></textarea>
    
            <p class="invalid-link" *ngIf="!isValidRegex(ItemData)">Неправильная ссылка</p>
    
              <div class="buttons">
        <div class="button" (click)="changeText()">Сохранить</div>
    </div>
    <div *ngIf="ItemData" class="video-wrap" [innerHTML]="this.ItemData|videoframePipe|safe"></div>
</div>

<div *ngIf="!IsSelected" class="input-wrap">
    <div *ngIf="ItemData" class="video-wrap" [innerHTML]="this.ItemData|videoframePipe|safe"></div>
    <div *ngIf="!ItemData" class="no-video">
        <p class="f-text" style="text-align:center">Нет видео</p>
    </div>
</div>
