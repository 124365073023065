<p class="not-saved-block" *ngIf="hasChanged && SelectedItem && Role == 'company'">Изменения не сохранены</p>

<div *ngIf="IsSelected" class="input-wrap" (click)="$event.stopPropagation()">

  <div class="input-wrap">

      <label>Выберите партнера</label>
      <ng-select
      *ngIf="PartnersList.length "
        [bindLabel]="'name'"
        [bindValue]="'id'"
        [placeholder]="'Не выбрано'"
        [notFoundText]="'Не найдено'"
        [searchable]="false"
        [clearable]="false"
        [appendTo]="'body'"
        [items]="PartnersList"
        [(ngModel)]="SelectedItem"
      >
      </ng-select>

  </div>

  <div class="buttons">
    <div class="button" (click)="changePartner()">Сохранить</div>
  </div>

</div>

<div *ngIf="!IsSelected" #partner class="input-wrap">
  <div *ngIf="ItemData"  class="partner-wrap">
    <div *ngIf="PartnerData" >

      <div *ngIf="Role == 'company' && !PartnerData.shown">
        <p class="not-saved-block left">Данный партнер скрыт от учеников!</p>
      </div>

      <!-- {{widthBlock}} -->
      <!-- {{PartnerData | json}} -->
      <div class="partner">
        <app-partners-item
          [id]="PartnerData.id"
          [name]="PartnerData.name"
          [bonus]="PartnerData.bonuses"
          [description]="PartnerData.description"
          [contacts]="PartnerData.contactInfo"
          [id]="PartnerData.id"
          [isSystem]="PartnerData.isSystem"
          [isBlock]="widthBlock && widthBlock < 440 ? true : false"
          [contacts]="ContactInfo"
          [ContactLoading]="ContactLoading"
          [showContact]="!EditMode"
          (getContact)="GetContact($event)"
        >
          </app-partners-item>
      </div>

      <!-- [showContact]="Role != 'company' || (Role == 'company' && PartnerData.isSystem)" -->



    </div>


    <div *ngIf="PartnerNotFounded">
      <p>Партнер не найден</p>
    </div>
  </div>
  <div *ngIf="!ItemData" class="no-partner">
      <p class="f-text" style="text-align:center">Нет партнера</p>
  </div>
</div>
