<h1 class="page-title f-title">
  Задание
  <div
    class="open-wrap"
    (click)="OpenLesson()"
    containerClass="customClass"
    tooltip="Открыть в режиме просмотра"
    [placement]="'bottom'"
    triggers="hover"
  >
    <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M15.9979 0.666748C12.2526 0.666748 8.93369 2.62979 6.43791 4.72216C3.91793 6.83482 2.05338 9.23353 1.19186 10.4318C0.514171 11.3744 0.514172 12.6257 1.19186 13.5683C2.05338 14.7666 3.91793 17.1653 6.43791 19.278C8.93369 21.3704 12.2526 23.3334 15.9979 23.3334C19.7432 23.3334 23.0621 21.3704 25.5579 19.278C28.0779 17.1653 29.9424 14.7666 30.8039 13.5683C31.4816 12.6257 31.4816 11.3744 30.8039 10.4318C29.9424 9.23353 28.0779 6.83482 25.5579 4.72216C23.0621 2.62979 19.7432 0.666748 15.9979 0.666748ZM15.9985 19.0834C19.9106 19.0834 23.0819 15.9121 23.0819 12.0001C23.0819 8.08806 19.9106 4.91675 15.9985 4.91675C12.0865 4.91675 8.9152 8.08806 8.9152 12.0001C8.9152 15.9121 12.0865 19.0834 15.9985 19.0834Z"
            fill="white"/>
      <path
        d="M20.2485 12.0001C20.2485 14.3473 18.3457 16.2501 15.9985 16.2501C13.6513 16.2501 11.7485 14.3473 11.7485 12.0001C11.7485 9.65287 13.6513 7.75008 15.9985 7.75008C18.3457 7.75008 20.2485 9.65287 20.2485 12.0001Z"
        fill="white"/>
    </svg>
  </div>

</h1>
<app-preloader
  *ngIf="
    loadingService.States[
      loadingService.getIdFromArrayStateByType(
        loadingService.StatesNames.GET_TESTS
      )
    ].value;else testsBlock"></app-preloader>
<ng-template #testsBlock>
  <div class="toggle-wrap" [ngClass]="{'toggle-true':Value}">
    <ng-toggle
      [value]="true"
      [color]="{
        unchecked: '#7D8FB3',
        checked: '#f62d51'
      }"
      [width]="50"
      [height]="30"
      [(ngModel)]="Value"
    ></ng-toggle>
    <p class="close" *ngIf="!Value">Добавить задание</p>
    <p class="open" *ngIf="Value">Добавить задание</p>
  </div>
  <div *ngIf="Value" class="test-content">
    <div class="list">
      <div class="item" *ngFor="let item of TestList">
        <app-close-test
          *ngIf="!item['open']"
          [id]="item['id']"
          [question]="item['question']"
          [answers]="item['answers']"
          [maxId]="item['maxId']||0"
          (OnDeleteQuestion)="DeleteQuestion($event)"
          (OnChangeQuestionText)="ChangeQuestionText($event)"
          (OnDeleteVariant)="DeleteVariant($event)"
          (OnAddVariant)="AddVariant($event)"
          (OnChangeVariant)="ChangeVariant($event)"
          [ErrorText]="item['error']"
          [EmptyQuestionList]="EmptyQuestionList"
          [FileName]="item?.attachedFile?.filename"
          [FileId]="item?.attachedFile?.fileId"
          [DownloadId]="DownloadId"
          (OnDelete)="DeleteFile(item['id'])"
          (OnUploadFile)="UploadFile(item['id'],$event)"
          (OnDownload)="DownloadFile(item.id,$event)"
        >
        </app-close-test>
        <app-open-test
          *ngIf="item['open']"
          [id]="item['id']"
          [question]="item['question']"
          (OnDeleteQuestion)="DeleteQuestion($event)"
          (OnChangeQuestionText)="ChangeQuestionText($event)"
          [ErrorText]="item['error']"
          [EmptyQuestionList]="EmptyQuestionList"
          [FileName]="item?.attachedFile?.filename"
          [FileId]="item?.attachedFile?.fileId"
          [DownloadId]="DownloadId"
          (OnDelete)="DeleteFile(item['id'])"
          (OnUploadFile)="UploadFile(item['id'],$event)"
          (OnDownload)="DownloadFile(item.id,$event)"
        >

        </app-open-test>
      </div>
    </div>
    <div class="add-buttons">
      <button class="btn-custom btn-default" (click)="AddCloseQuestion()">
        <div class="svg-wrap left">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8 4C7.56 4 7.2 4.36 7.2 4.8V7.2H4.8C4.36 7.2 4 7.56 4 8C4 8.44 4.36 8.8 4.8 8.8H7.2V11.2C7.2 11.64 7.56 12 8 12C8.44 12 8.8 11.64 8.8 11.2V8.8H11.2C11.64 8.8 12 8.44 12 8C12 7.56 11.64 7.2 11.2 7.2H8.8V4.8C8.8 4.36 8.44 4 8 4ZM8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8.00007 14.4011C4.47207 14.4011 1.60007 11.5291 1.60007 8.0011C1.60007 4.4731 4.47207 1.6011 8.00007 1.6011C11.5281 1.6011 14.4001 4.4731 14.4001 8.0011C14.4001 11.5291 11.5281 14.4011 8.00007 14.4011Z"
                  fill="white"/>
          </svg>
        </div>
        Добавить тест
      </button>
      <button class="btn-custom btn-default" (click)="AddOpenQuestion()">
        <div class="svg-wrap left">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8 4C7.56 4 7.2 4.36 7.2 4.8V7.2H4.8C4.36 7.2 4 7.56 4 8C4 8.44 4.36 8.8 4.8 8.8H7.2V11.2C7.2 11.64 7.56 12 8 12C8.44 12 8.8 11.64 8.8 11.2V8.8H11.2C11.64 8.8 12 8.44 12 8C12 7.56 11.64 7.2 11.2 7.2H8.8V4.8C8.8 4.36 8.44 4 8 4ZM8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8.00007 14.4011C4.47207 14.4011 1.60007 11.5291 1.60007 8.0011C1.60007 4.4731 4.47207 1.6011 8.00007 1.6011C11.5281 1.6011 14.4001 4.4731 14.4001 8.0011C14.4001 11.5291 11.5281 14.4011 8.00007 14.4011Z"
                  fill="white"/>
          </svg>
        </div>
        Добавить открытый вопрос
      </button>
    </div>
    <div class="settings form" [formGroup]="form">
      <div class="input-wrap input-wrap--limit">
        <label>Макс. кол-во допустимых ошибок</label>
        <input
          type="number"
          min="0"
          formControlName="limitErrors"
          oninput="validity.valid||(value='');"
          class="form-input"
          placeholder="Введите кол-во">
      </div>
      <div class="input-wrap">
        <label>Проверка открытых вопросов УК</label>
        <div class="toggle-wrap " [ngClass]="{'toggle-true':this.form.get('testCheckNeeded')?.value}">
          <ng-toggle
            [color]="{
                                  unchecked: '#7D8FB3',
                                  checked: '#f62d51'
                                }"
            [width]="50"
            [height]="30"
            formControlName="testCheckNeeded"
          ></ng-toggle>
          <p class="close" *ngIf="!this.form.get('testCheckNeeded')?.value">Не требуется</p>
          <p class="open" *ngIf="this.form.get('testCheckNeeded')?.value">Требуется</p>

        </div>
      </div>
    </div>

  </div>

  <div class="buttons">
    <app-preloader
      *ngIf="
        loadingService.States[
          loadingService.getIdFromArrayStateByType(
            loadingService.StatesNames.EDIT_TESTS
          )
        ].value;else save"></app-preloader>
    <ng-template #save>
      <p *ngIf="!LessonId">Вы сможете сохранить тесты после того как создадите урок</p>
      <button *ngIf="LessonId" (click)="SaveTests()" class="btn-custom btn-active btn-xl">Сохранить изменения</button>
    </ng-template>
  </div>
</ng-template>
