import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ConstantsService {

  FileMaxSize = 31457280;
  FileMaxSizeText = '30МБ';
  VideoMaxSize = 2147483648;
  VideoMaxSizeText = '2ГБ';
}
