<app-modal [header]="headerRef" (destroy)="dismiss()">
    <div class="file-block" modal-content>
        <div class="modal-content">
            <p class="f-text">{{msg}}</p>
            <div
                    *ngIf="
                loadingService.States[
                  loadingService.getIdFromArrayStateByType(
                    loadingService.StatesNames.DELETE_NEWS
                  )
                ].value ||
                loadingService.States[
                  loadingService.getIdFromArrayStateByType(
                    loadingService.StatesNames.DELETE_NEWS
                  )
                ].value;else btn"
            >
                <app-preloader></app-preloader>
            </div>
            <ng-template #btn>
                <div class="buttons-modal">
                    <button  class="btn-custom btn-active" (click)="close(true)">
                        Да
                    </button>
                    <button class="btn-custom btn-delete"   (click)="dismiss(false)">
                        Нет
                    </button>

                </div>
            </ng-template>
        </div>
    </div>


</app-modal>
<ng-template #headerRef>
    <h6 class="modal--header f-title">{{title}}</h6>
</ng-template>
