<div>
  <input
    #_fileInput
    type="file"
    (change)="openCropperDialog($event)"
    accept="image/png,image/jpeg"
    hidden
  />
  <!--<button ly-button color="primary" (click)="_fileInput.click()">
    <img src="./assets/img/base/card-design/plus.svg" />
  </button>-->
  <div (click)="_fileInput.click()">
    <ng-content></ng-content>
  </div>
</div>
