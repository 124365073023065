import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";
import {catchError, EMPTY, forkJoin, of, switchMap} from "rxjs";
import {tap} from "rxjs/operators";
import {Location, NgClass, NgIf} from "@angular/common";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {PreloaderComponent} from "../../../../../shared/ui/preloader/preloader.component";
import {ImageCropperComponent} from "../../../../../shared/ui/image-cropper/image-cropper.component";
import {InputRefDirective} from "../../../../../shared/directives/input-trim.directive";
import {EventsService} from "../../../../../shared/services/events.service";
import {LoadingService} from "../../../../../shared/services/loading.service";
import {EducationService} from "../../../../../shared/services/education.service";
import {ConstantsService} from "../../../../../shared/services/constants.service";
import {LyDialog} from "@alyle/ui/dialog";
import {LyOverlay} from "@alyle/ui";

@Component({
  selector: 'app-create-lesson-info',
  templateUrl: './create-lesson-info.component.html',
  styleUrls: ['./create-lesson-info.component.scss'],
  imports: [
    TooltipModule,
    PreloaderComponent,
    ImageCropperComponent,
    NgIf,
    NgClass,
    ReactiveFormsModule,
    InputRefDirective
  ],
  standalone: true,
  providers:[
    {provide:LyDialog},
    {provide:LyOverlay},

  ]
})
export class CreateLessonInfoComponent implements OnInit{

  @Input() CourseId = 0;
  @Input() blockId = 0;
  @Input() LessonId = 0;

  notification = false;

  @Output() OnUpdateLessonId = new EventEmitter();

  NameLength = 200;

  form = new FormGroup({
    name: new FormControl('',[Validators.required, Validators.maxLength(this.NameLength)]),
  })
  initialForm: any = {};



  icon:any = null;
  iconPreview:any = null;
  header:any = null;
  headerPreview:any = null;

  headerLink = null;
  iconLink = null;

  haveUnsavedChanges = false;


  constructor(
      public loadingService: LoadingService,
      private eventsService: EventsService,
      private sanitizer: DomSanitizer,
      private educationService: EducationService,
      private router: Router,
      private location: Location,
      private constantsService: ConstantsService


  ) {
  }

  ngOnInit(): void {
    this.InitLessonData();
  }


  InitLessonData(){
      if(this.LessonId !==0) {
          this.GetLessonItem().subscribe()
      }

  }

  Send(){
    if(this.LessonId === 0){
      this.CreateLesson();
    } else{
      this.EditLesson();
    }
  }

  CreateLesson(){
    this.eventsService.resetAllEvents();
    for (const i in this.form.controls) {
      this.form.get(i)?.updateValueAndValidity();
    }
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
    const valid = this.form.valid;
    if(valid){
      const form = new FormData();
      form.append('name ', String(this.form.get('name')?.value));
      form.append('blockId  ', String(this.blockId));
      if(this.icon){
        form.append('icon', this.icon);
      }

      if(this.header){
        form.append('header', this.header);
      }

      this.educationService.CreateLesson(form)
          .pipe(
              catchError(()=>{
                this.router.navigate(['base','education','course',this.CourseId])
                return EMPTY;
              }),
              switchMap((res)=>{
                this.LessonId = res['id'];
                this.location.go(`/base/company/education/lesson-create/${this.LessonId}?courseID=${this.CourseId}&blockId=${this.blockId}`)
                this.OnUpdateLessonId.emit({LessonId:this.LessonId})
                this.icon = null;
                this.header = null;
                return this.GetLessonItem();
              }),
          )
          .subscribe((res)=>{
        this.eventsService.success('Урок успешно создан!');

      })

    }

  }







  EditLesson(){
    this.eventsService.resetAllEvents();
    for (const i in this.form.controls) {
      this.form.get(i)?.updateValueAndValidity();
    }
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
    const valid = this.form.valid;
    if(valid){
      const form = new FormData();
      form.append('name ', String(this.form.get('name')?.value));
      form.append('blockId  ', String(this.blockId));
      form.append('notification  ', String(this.notification));
      if(this.icon){
        form.append('icon', this.icon);
      }

      //Удалили старое изображение и не добавили новое
      if(this.iconPreview === null && this.icon === null){
        form.append('icon', new Blob());
      }

      if(this.header){
        form.append('header', this.header);
      }

      if(this.headerPreview === null && this.header === null){
        form.append('header', new Blob());
      }

      this.educationService.EditLesson(this.LessonId,form)
          .pipe(
              catchError(()=>{
                this.router.navigate(['base','education','course',this.CourseId])
                return EMPTY;
              }),
              switchMap((res)=>{
                this.LessonId = res['id'];
                this.notification = false;

                this.icon = null;
                this.header = null;
                return this.GetLessonItem();
              })
          )
          .subscribe(()=>{
        this.eventsService.success('Урок успешно изменён!');
      })

    }

  }

  handleFileInputIcon(file: any) {
    let size:number = +file['size'];
    if(size<= this.constantsService.FileMaxSize) {
      this.icon = file;
      this.iconPreview = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file))
      this.haveUnsavedChanges = true;
    }else{
      this.eventsService.throwError(`Размер изображения не должен превышать ${this.constantsService.FileMaxSizeText}!`)
    }
  }

  DeleteIcon(){
    this.icon = null;
    this.iconPreview = null;

    this.haveUnsavedChanges = true;
  }

  handleFileInputHeader(file: any) {

    let size:number = +file['size']
    if(size<=this.constantsService.FileMaxSize) {
      this.header = file;
      this.headerPreview = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file))
      this.haveUnsavedChanges = true;
    }else{
      this.eventsService.throwError(`Размер изображения не должен превышать ${this.constantsService.FileMaxSizeText}!`)
    }
  }

  DeleteHeader(){
    this.header = null;
    this.headerPreview = null;
    this.haveUnsavedChanges = true;
  }

  GetLessonItem(){
    return this.educationService.GetLessonItem(this.LessonId, this.loadingService.StatesNames.GET_LESSON)
        .pipe(
            switchMap((res)=>{
              this.haveUnsavedChanges = false;
              //console.log("GetLessonItem")
              this.form.get('name')!.setValue(res['name']);
              this.iconLink = res['iconUrl'];
              this.headerLink = res['headerUrl'];

              this.initialForm = this.form.value;
              return forkJoin([this.getIcon(), this.getHeader()])
            }))
  }

  getIcon(){
    if(this.iconLink){
      return this.educationService.GetLessonImage(this.LessonId,'icon')
          .pipe(
              tap((res:any)=>{
                this.iconPreview = res;
              })
          )
    } else{
      return of(1);
    }

  }

  getHeader(){
    if(this.headerLink){
      return this.educationService.GetLessonImage(this.LessonId,'header')
          .pipe(
              tap((res:any)=>{
                this.headerPreview = res;
              })
          )
    } else{
      return  of(1)
    }

  }




  ChangeNotification() {
    this.notification = !this.notification;
    this.haveUnsavedChanges = true;
  }


  haveUnsaved() {
    //console.log("this.initialForm",this.initialForm);
    if(
      Object.entries(this.initialForm).some(
        ([field, value]) => value !== this.form.get(field)?.value)
      ) {
      return true;
    }
    return this.haveUnsavedChanges;
  }

  OpenLesson(){
    let host:any = window.location.href.split('?')[0].split('/')||'';
    host[5]='lesson-info';
    let url:string = host.join('/');
    // @ts-ignore
    window.open(url, '_blank').focus();
  }
}
