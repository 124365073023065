import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {NotFoundComponent} from "./shared/ui/not-found/not-found.component";
import {Auth_Routes} from "./auth/auth.routes";
import {BaseRoutes} from "./base/base.routes";

export const AppRoutes: Routes = [
    { path: "", pathMatch: "full", redirectTo: "auth" },
    {
      path: "auth",
      loadChildren: () => {
        return import("./auth/auth.routes").then(
            (m) => m.Auth_Routes
        );
      },
    },
    {
      path: "base",
      loadChildren: () => {
        return import("./base/base.routes").then(
            (m) => m.BaseRoutes
        );
      },
    },
    {
        path: '404', component: NotFoundComponent,
    },

    {
        path: '**', redirectTo: '404',
    }

];
