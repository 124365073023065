import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import * as Editor from 'ckeditor5/build/ckeditor';
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {FormsModule} from "@angular/forms";
import {LoadingService} from "../../../../../shared/services/loading.service";
import {EventsService} from "../../../../../shared/services/events.service";
import {PreloaderComponent} from "../../../../../shared/ui/preloader/preloader.component";
import {NgIf} from "@angular/common";
import {ConstantsService} from "../../../../../shared/services/constants.service";

@Component({
  selector: 'app-open-test',
  templateUrl: './open-test.component.html',
  styleUrls: ['./open-test.component.scss'],
  imports: [
    CKEditorModule,
    FormsModule,
    PreloaderComponent,
    NgIf
  ],
  standalone: true
})
export class OpenTestComponent {

  constantsService = inject(ConstantsService);
  eventsService = inject(EventsService);
  protected loadingService = inject(LoadingService)

  public Editor1:any = Editor;
  config:any = {
    toolbar: [
      'heading', '|',
      'fontfamily', 'fontsize','fontColor','fontBackgroundColor',
      'alignment', '|',
      'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
      'link',
      'bulletedList', 'numberedList', '|',
      'insertTable', '|',
      'undo', 'redo',
      'removeFormat'
    ],

    heading: {
      options: [
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' }
      ]
    },
    fontSize:{
      options:
        Array.from({length: 65}, (_, i) => i + 8),

    },
    image: {
      // Configure the available styles.
      styles: [
        //'alignBlockLeft', 'alignCenter', 'alignBlockRight'
      ],

      // Configure the available image resize options.
      resizeOptions: [
        {
          name: 'resizeImage:original',
          label: 'Original',
          value: null
        },
        {
          name: 'resizeImage:50',
          label: '25%',
          value: '25'
        },
        {
          name: 'resizeImage:50',
          label: '50%',
          value: '50'
        },
        {
          name: 'resizeImage:75',
          label: '75%',
          value: '75'
        }
      ],

      // You need to configure the image toolbar, too, so it shows the new style
      // buttons as well as the resize buttons.
      toolbar: [
        'imageStyle:alignBlockLeft', 'imageStyle:alignCenter', 'imageStyle:alignBlockRight',
        '|',
        'ImageResize',
        '|',
        'imageTextAlternative'
      ]
    },
    table: {
      tableCellProperties: {
        defaultProperties: {
          verticalAlignment: 'center',
        }
      }
    },


    language: 'ru'
  };

  @Input() id = 0;
  @Input() question = '';
  @Input() ErrorText = false;


  @Output() OnChangeQuestionText = new EventEmitter();
  @Output() OnDeleteQuestion = new EventEmitter();


  @Input() EmptyQuestionList:any = [];

  @Input() FileId = null;
  @Input() FileName:any = null;
  @Input() DownloadId:any = null;
  @Output() OnUploadFile = new EventEmitter();
  @Output() OnDelete = new EventEmitter();
  @Output() OnDownload = new EventEmitter();


  ChangeQuestionText(){
    this.OnChangeQuestionText.emit(
        {
          id: this.id,
          question: this.question
        }
    )
  }

  DeleteTest(){
    this.OnDeleteQuestion.emit({id:this.id})
  }

  DeleteFile(){
    this.OnDelete.emit();
  }

  UploadFile(files: any){
    const file = files.files.item(0);
    let size:number = +(file['size'])
    if(size<=this.constantsService.FileMaxSize) {
      this.OnUploadFile.emit(file)
    }else{
      this.eventsService.throwError(`Размер файла не должен превышать ${this.constantsService.FileMaxSizeText}!`)
    }
  }

  DownloadFile(){
    this.OnDownload.emit({
      fileId: this.FileId,
      fileName: this.FileName
    });



  }



    protected readonly Editor = Editor;
}
