import {Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import * as Editor from "ckeditor5-custom-build";
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {PreloaderComponent} from "../../../../../shared/ui/preloader/preloader.component";
import {ConstantsService} from "../../../../../shared/services/constants.service";
import {EventsService} from "../../../../../shared/services/events.service";
import {LoadingService} from "../../../../../shared/services/loading.service";

@Component({
  selector: 'app-close-test',
  templateUrl: './close-test.component.html',
  styleUrls: ['./close-test.component.scss'],
  imports: [
    CKEditorModule,
    PreloaderComponent,
    NgIf,
    NgClass,
    FormsModule,
    NgForOf
  ],
  standalone: true
})
export class CloseTestComponent implements OnInit {

  constantsService = inject(ConstantsService);
  eventsService = inject(EventsService);
  protected loadingService = inject(LoadingService)


  @Input() id = 0;
  @Input() maxId = 0;
  @Input() question = '';
  @Input() answers: any[] = [];
  @Input() EmptyQuestionList: any = [];
  @Input() ErrorText = false;


  @Output() OnAddVariant = new EventEmitter();
  @Output() OnChangeVariant = new EventEmitter();
  @Output() OnChangeQuestionText = new EventEmitter();
  @Output() OnDeleteQuestion = new EventEmitter();
  @Output() OnDeleteVariant = new EventEmitter();

  @Input() FileId = null;
  @Input() FileName: any = null;
  @Input() DownloadId: any = 0;

  @Output() OnUploadFile = new EventEmitter();
  @Output() OnDelete = new EventEmitter();
  @Output() OnDownload = new EventEmitter();

  maxVariantId = 0;
  public Editor1: any = Editor;
  config: any = {
    toolbar: [
      'heading', '|',
      'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
      'alignment', '|',
      'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
      'link',
      'bulletedList', 'numberedList', '|',
      'insertTable', '|',
      'undo', 'redo',
      'removeFormat'
    ],

    heading: {
      options: [
        {model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
        {model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1'},
        {model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2'},
        {model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3'},
        {model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4'},
        {model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5'}
      ]
    },
    fontSize: {
      options:
        Array.from({length: 65}, (_, i) => i + 8),

    },
    image: {
      // Configure the available styles.
      styles: [
        //'alignBlockLeft', 'alignCenter', 'alignBlockRight'
      ],

      // Configure the available image resize options.
      resizeOptions: [
        {
          name: 'resizeImage:original',
          label: 'Original',
          value: null
        },
        {
          name: 'resizeImage:50',
          label: '25%',
          value: '25'
        },
        {
          name: 'resizeImage:50',
          label: '50%',
          value: '50'
        },
        {
          name: 'resizeImage:75',
          label: '75%',
          value: '75'
        }
      ],

      // You need to configure the image toolbar, too, so it shows the new style
      // buttons as well as the resize buttons.
      toolbar: [
        'imageStyle:alignBlockLeft', 'imageStyle:alignCenter', 'imageStyle:alignBlockRight',
        '|',
        'ImageResize',
        '|',
        'imageTextAlternative'
      ]
    },
    table: {
      tableCellProperties: {
        defaultProperties: {
          verticalAlignment: 'center',
        }
      }
    },


    language: 'ru'
  };


  ChangeQuestionText() {
    this.OnChangeQuestionText.emit(
      {
        id: this.id,
        question: this.question
      }
    )
  }

  DeleteTest() {
    this.OnDeleteQuestion.emit({id: this.id})
  }

  DeleteVariant(variantId: number) {
    this.OnDeleteVariant.emit(
      {
        id: this.id, variantId
      }
    )
  }

  AddVariant() {
    const id = this.maxId + 1;
    this.OnAddVariant.emit({
      id: this.id,
      variantId: id,
      right: this.answers.length < 1,
      error: false
    })

  }


  ChangeRightAnswer(item: any, event: any) {
    item['right'] = !item['right'];
    this.ChangeAnswers(item);
  }

  ChangeAnswers(item: any) {
    item['error'] = item['text'] === '';
    this.OnChangeVariant.emit({
      id: this.id,
      item
    })


  }

  DeleteFile() {
    this.OnDelete.emit();
  }

  UploadFile(files: any) {
    const file = files.files.item(0);
    let size: number = +(file['size'])
    if (size <= this.constantsService.FileMaxSize) {
      this.OnUploadFile.emit(file)
    } else {
      this.eventsService.throwError(`Размер файла не должен превышать ${this.constantsService.FileMaxSizeText}!`)
    }
  }

  DownloadFile() {
    this.OnDownload.emit({
      fileId: this.FileId,
      fileName: this.FileName
    });
  }

  ngOnInit(): void {
  }

  protected readonly Editor = Editor;

}
