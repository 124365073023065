import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, EMPTY, Observable, of, ReplaySubject, Subject, throwError} from 'rxjs';
import {debounceTime, delay, pluck, switchMap, take, tap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {LoadingService, LoadingStates} from './loading.service';
import {EventsService} from "./events.service";
import {DownloadService} from "./download.service";

@Injectable({
  providedIn: "root",
})
export class HTTPService {
  ServerURL = `${environment.apiUrl}/v1`;


  IsLoginedChange$ = new Subject<boolean>();
  IsLogined = false;

  UpdateStyle$ = new Subject<any>();
  ScrollToBottom$ = new Subject<any>();

  IsLoadingChange$ = new Subject<boolean>();

  CurrentJob: any = [];

  IsUserChange$ = new ReplaySubject<any>(1);
  User: any = {
    id: null,
    phone: "",
    first_name: "",
    last_name: "",
    createdAt: "",
    profileType: "",
    active: true,
    banned: false,
    needReports: false,
    ownerId: null,
    companyId: null
  };


  headers = new HttpHeaders({
    "Access-Control-Allow-Origin": "*",
  });

  // LoadingSubject: Subject<any> = new Subject();

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    public loadingService: LoadingService,
    private eventsService: EventsService,
    private downloadService: DownloadService
  ) {
    this.IsLoginedChange$.next(false);
    this.IsLoginedChange$.subscribe((val) => {
      //console.log("User", this.User)
      let prevIsLogined = this.IsLogined;
      this.IsLogined = val;
      if (this.IsLogined && !prevIsLogined) {
        //console.log("NAV 1")
        this.router.navigate(["/", "base"]);
      } else if (
        this.IsLogined &&
        (this.isInsideAuthModule())
      ) {

        this.router.navigate(["/", "base"]);
      } else if (this.IsLogined && !this.isInsideBase()) {
        //console.log("NAV 3", window.location.href, this.route)
        this.router.navigate(["/", "base"]);
      }
      if (!this.IsLogined) {
        //console.log("NAV 4")
        this.router.navigate(["auth", "login"]);
      }
    });
  }

  CheckToken() {
    if (localStorage.getItem("token")) {
      // this.isLoading = true;
      this.IsLoadingChange$.next(true);
      if (localStorage.getItem("token")) {
        this.BaseInitByToken(localStorage.getItem("token"));
        this.IsLogined = localStorage.getItem("token") ? true : false;
        //console.log("IsLogined",this.IsLogined)
      }

      this.GetMe().subscribe(
        (res) => {
          this.User = res;
          localStorage.setItem('profileType', this.User.profileType)
          // this.isLoading = false;
          this.IsUserChange$.next(true);
          this.IsLoginedChange$.next(true);
          this.IsLoadingChange$.next(false);


        },
        (err) => {
          this.ClearSession();
        }
      );
    } else {
      // this.isLoading = false;
      this.ClearSession();
    }
  }

  BaseInitByToken(data: any) {
    if (data) {
      if (!this.headers.has("Authorization")) {
        this.headers = this.headers.append("Authorization", data);
      } else {
        this.headers = this.headers.set("Authorization", data);
      }
    }
  }

  private isInsideAuthModule() {
    return window.location.href.includes("auth") ? true : false;
  }

  private isInsideBase() {
    return window.location.href.includes("base");
  }


  Login(payload: any) {
    return this.RequestHandler(() => {
      return this.http.post(this.ServerURL + "/user/auth", payload).pipe(
        tap((res: any) => {
            localStorage.setItem('profileType', res.profile.profileType)
          }
        ));
    }, this.loadingService.StatesNames.LOGIN)

      ;
  }

  Register(payload: any) {
    return this.RequestHandler(() => {
      return this.http.post(this.ServerURL + "/profile/register", payload);
    }, this.loadingService.StatesNames.REGISTER);
  }

  ValidateInvite(token: string) {
    return this.RequestHandler(() => {
      return this.http.get(this.ServerURL + `/profile/validate-invite?token=${token}`);
    }, this.loadingService.StatesNames.REGISTER_VALIDATE);
  }

  ClearSession() {
    localStorage.setItem("token", "");
    localStorage.removeItem("token");
    localStorage.removeItem("menu");
    localStorage.removeItem("subMenu");
    this.headers = new HttpHeaders({
      "Access-Control-Allow-Origin": "*",
    });
    localStorage.setItem('profileType', '')
    localStorage.removeItem('profileType')
    if (!this.isInsideAuthModule()) {
      //console.log("Navigate to auth");
      this.router.navigate(["auth", "login"]);
    }
  }

  Logout() {
    return this.RequestHandler(() => {
      return this.http
        .post(this.ServerURL + "/user/logout", {}, {headers: this.headers})
    }, this.loadingService.StatesNames.LOGOUT);
  }


  GetMe(State: any = this.loadingService.StatesNames.GLOBAL) {
    return this.SendGet("/user/me").pipe();
  }


  RequestHandler(
    runRequest: () => Observable<any>,
    RequestType = LoadingStates.UNKNOWN
  ): Observable<any> {
    let loadingId = this.loadingService.addLoading(RequestType);
    //console.log(`[Request handler] with ID = ${loadingId}`)
    return runRequest().pipe(
      // delay(2000),
      debounceTime(50),
      tap(
        (dataRes) => {
          //console.log(`${loadingId} : DataRes = `, dataRes)
          this.loadingService.removeLoading(loadingId);
        },
        (catchError) => {
          this.loadingService.removeLoading(loadingId);
        }
      ),
      catchError(err => {
        this.eventsService.resetAllEvents();
        if (err.status === 403 && !err.error.messageText && !err.error.message && this.getProfileUrl(this.User.profileType) === 'franchise') {
          return this.GetMe()
            .pipe(
              switchMap((res: any) => {
                this.eventsService.resetAllEvents();
                this.User = res;
                this.IsUserChange$.next(true);
                return EMPTY;
              }),
              catchError((err) => {
                this.eventsService.resetAllEvents();
                this.ClearSession();
                return EMPTY;
              })
            );
        }
        if (err.status === 403 && err.error.messageText === 'Profile is banned') {
          this.eventsService.throwError('Ваш профиль был заблокирован.')
          this.ClearSession();
          return EMPTY;
        }

        if (err.status === 401) {
          this.ClearSession();
          return EMPTY;
        }

        return throwError(err);


      })
    );
  }

  SendPost(method: string, data: any, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.post(this.ServerURL + method, data, {
        headers: this.headers,
      });
    }, type);
  }

  SendDownloadGet(method: string, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.get(this.ServerURL + method, {
        headers: this.headers,
        responseType: "blob" as "json",
        observe: "response",
      });
    }, type)
      .pipe(tap(this.downloadService.saveResponse2File));

  }

  SendPut(method: string, data: any, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.put(this.ServerURL + method, data, {
        headers: this.headers,
      });
    }, type);
  }

  SendGet(method: string, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.get(this.ServerURL + method, {headers: this.headers});
    }, type);
  }

  SendGetText(method: string, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http
        .get(this.ServerURL + method, {
          headers: this.headers,
          responseType: "text",
        })
        .pipe(
          tap((data) => {
            //console.log(data, typeof(data))
          })
        );
    }, type);
  }

  SendDelete(method: string, data: any, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.delete(this.ServerURL + method, {
        headers: this.headers,
        body: data
      });
    }, type);
  }

  SendPatch(method: string, data: any, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.patch(this.ServerURL + method, data, {
        headers: this.headers,
      });
    }, type);
  }

  SendPatchProgressBar(method: string, data: any, type = LoadingStates.UNKNOWN) {
      return this.http.patch(this.ServerURL + method, data, {
        headers: this.headers,
        reportProgress:true,
        observe: 'events',
        responseType: 'json'
      });

  }

  getFile(url: string) {
    //console.log("get file", url)
    return this.http
      .get(`${this.ServerURL}` + url, {
        headers: this.headers,
        responseType: "blob" as "json",
        observe: "response",
      })
      .pipe(tap(this.downloadService.saveResponse2File));
  }


  getProfileUrl(profile: string) {
    switch (profile) {
      case 'CONTROL_COMPANY':
        return 'company'
      case 'FRANCHISE':
        return 'franchise'
      case 'STAFF':
        return 'staff'
      default:
        return '';

    }
  }

  getFormattedProfileType() {
    return this.getProfileUrl(this.User.profileType);
  }

  getCompanyDesign(type?: string) {
    return this.SendGet('/company/design', this.loadingService.StatesNames.GET_DESIGN)
  }

  EditCompanyDesign(data: any, loading = this.loadingService.StatesNames.CONFIRM) {
    return this.SendPatch('/company/design', data, loading)
  }

  GetCompanyDictionary() {
    return this.SendGet('/company/dictionary', this.loadingService.StatesNames.GET_DESIGN)
      .pipe(
        tap((res) => {
          this.CurrentJob = res;
        })
      )
      ;
  }

  capitalizeFirstLetter(string: string) {
    //console.log("capitalizeFirstLetter",string);
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : null;
    //return string.charAt(0).toUpperCase() + string.slice(1);
  }

  ResetPassword(data: any) {
    return this.SendPatch('/user/reset-password', data, this.loadingService.StatesNames.RESET)

  }

  InitRecoveryPassword(email: any) {
    return this.SendPost('/user/init-password-reset', {email}, this.loadingService.StatesNames.RECOVERY)

  }

  GetIntegrationToken() {
    return this.SendGet('/access-token', this.loadingService.StatesNames.GET_TOKEN)
  }

  NewntegrationToken() {
    return this.SendPut('/access-token', this.loadingService.StatesNames.SET_TOKEN)
  }


}
