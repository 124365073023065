import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  Sanitizer,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {OnChange} from "ngx-bootstrap/utils";
import { isValidLink } from 'src/app/shared/validators/video.validators';
import { EMPTY, catchError, of, switchMap, tap, throwError } from 'rxjs';
import { PartnersService } from 'src/app/shared/services/partners.service';
import {FormControl, FormGroup, FormsModule, Validators} from '@angular/forms';
import {NgIf} from "@angular/common";
import {NgSelectModule} from "@ng-select/ng-select";
import {HTTPService} from "../../../../../shared/services/main.service";
import {EducationService} from "../../../../../shared/services/education.service";
import {PartnersItemComponent} from "../../../../partners/partners-list/partners-item/partners-item.component";
import {LoadingService} from "../../../../../shared/services/loading.service";


@Component({
  selector: 'app-lesson-content-item-partner',
  templateUrl: './lesson-content-item-partner.component.html',
  styleUrls: ['./lesson-content-item-partner.component.scss'],
  imports: [
    NgIf,
    NgSelectModule,
    PartnersItemComponent,
    FormsModule
  ],
  standalone: true
})
export class LessonContentItemPartnerComponent implements OnInit{
  @Input() ItemUid: string = '';
  @Input() ItemData: any = null;
  @Input() IsSelected = false;
  mainService = inject(HTTPService)
  Role: string = this.mainService.getFormattedProfileType();
  @Input() EditMode = false;

  @Output() OnChanged = new EventEmitter<any>();


  InitData: any = null;
  SelectedItem: any = null;

  widthBlock: number | null = null;

  PartnersList = [];

  ContactInfo: any = null;
  ContactLoading: boolean = false;

  PartnerData: any = null;
  PartnerNotFounded = false;

  @ViewChild('partner') partner: ElementRef | undefined;

  @HostListener('window:resize', ['$event'])
    onResize(event: any) {
      this.checkSize();
    }


  constructor(
    public loadingService: LoadingService,
    private educationService: EducationService,
    private partnersService: PartnersService,
  ) {}


  ngOnInit(): void {
    this.InitData = this.ItemData;
    if(this.Role === 'company'){
      this.getPartnersShortList().subscribe();
    } else {
      if(this.ItemData) {
        this.getPartnerInfo(this.ItemData).subscribe();
      }
    }

  }

  checkSize() {
    setTimeout(() => {
      if(this.partner) {
        this.widthBlock = this.partner.nativeElement.offsetWidth
        }
    }, 100);
  }


  get hasChanged() { return this.SelectedItem != this.ItemData };

  changePartner() {
    this.ChangeItem().subscribe()
  }

  getPartnersShortList() {
    return this.partnersService.GetPartnersShortList().pipe(tap(
      (res) => {this.PartnersList = res}
    ), switchMap(
      (r) => {
        if(this.ItemData) {
          this.SelectedItem = +this.ItemData;
          return this.getPartnerInfo(this.ItemData);
        } else {
          return of(1);
        }
      }
    ));
  }

  getPartnerInfo(id: number) {
    return this.partnersService.GetPartner(id).pipe(
      catchError(()=>{
        this.SelectedItem = null;
        this.PartnerNotFounded = true;
        return EMPTY;
    }),
      tap(
       (r) => {this.PartnerData = r; this.PartnerNotFounded = false; this.checkSize()}
    ));
  }

  ChangeItem() {
    const form = new FormData();
    form.append('content', String(this.SelectedItem));

    return this.educationService.EditLessonContentData(this.ItemUid, form)
        .pipe(
          tap((res) => {

            this.ItemData = this.SelectedItem;


            this.getPartnerInfo(this.ItemData).subscribe(
              (res) => {
                this.InitData = this.ItemData;
                this.OnChanged.emit(this.ItemData)
              }
            );

          })
    )
  }

  GetContact(id: number) {
    // const partner = this.partners().find((item: any) => item.id === id)
    // partner['contactloading'] = true;
    this.ContactLoading = true;
    this.partnersService.GetPartnerContact(id)
      .pipe(
        catchError(() => {
          this.ContactLoading = false;
          // this.LoadingList = this.LoadingList.filter(item => item !== id);
          // this.partners().find((item: any) => item.id === id)['contactloading'] = false;
          return EMPTY;
        }),
        tap((res: { value: string }) => {
          this.ContactInfo = res['value'];
          this.ContactLoading = false;
          if(this.isLink(this.ContactInfo)){
            window.open(this.ContactInfo, "_blank");
          }
        })
      ).subscribe();
  }

  isLink(value: string): boolean {
    const pattern = /^(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?$/;
    return pattern.test(value);
  }



}
