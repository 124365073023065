import { Component, EventEmitter, Input, Output } from '@angular/core';
// import { FormControl, FormGroup } from '@angular/forms';
import { tap } from 'rxjs';
import { EducationService } from 'src/app/shared/services/education.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import {NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {VideoFramePipe} from "../../../../../shared/pipes/videoframe.pipe";
import {SafeHtml} from "../../../../../shared/pipes/SafeHtml";
import {isValidLink} from "../../../../../shared/validators/video.validators";

@Component({
  selector: 'app-lesson-content-item-video',
  templateUrl: './lesson-content-item-video.component.html',
  styleUrls: ['./lesson-content-item-video.component.scss'],
  imports: [
    NgIf,
    FormsModule,
    VideoFramePipe,
    SafeHtml
  ],
  standalone: true
})
export class LessonContentItemVideoComponent {
  @Input() ItemUid: string = '';
  @Input() ItemData: any = null;
  @Input() IsSelected = false;

  @Output() OnChanged = new EventEmitter<any>();
  InitData: any = null;

  constructor(
    public loadingService: LoadingService,
    private educationService: EducationService,
  ) {}

  ngOnInit(): void {
   this.InitData = this.ItemData;
  }

  get hasChanged() { return this.InitData != this.ItemData };

  changeText() {
    this.ChangeItem().subscribe()
  }

  /*getVideoIframe() {
    if(this.ItemData.includes('shorts')){
      const value = this.ItemData.split('shorts/')[1];
      return  `<iframe width="315" style="max-width:100%" height="560" src="https://www.youtube.com/embed/${value}" title="YouTube" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
    } else{
      return  `<iframe width="560" style="max-width:100%" height="315" src="https://www.youtube.com/embed/${this.ItemData}" title="YouTube" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
    }

  }*/
  getVideoLink() {

    if(this.ItemData){
      return this.ItemData;
    }
    else return ''
  }

  onChangeLink(event: string) {
    event = event.replaceAll(/ /g, '');
    if(!event || event == '') {
      this.ItemData = ''
      return
    }

    if (!this.isValidRegex(event)) {
      this.ItemData = ''
      return
    }
    this.ItemData = event;
  }

  ChangeItem() {
    const form = new FormData();
    form.append('content', String(this.ItemData));

    return this.educationService.EditLessonContentData(this.ItemUid, form)
        .pipe(
          tap((res) => {
            this.OnChanged.emit(this.ItemData)
            this.InitData = this.ItemData;
          })
    )
  }

  isValidRegex(s: string) {
    return isValidLink(s).status;
  }
}
