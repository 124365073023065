import { lyl, StyleRenderer, ThemeRef, ThemeVariables } from "@alyle/ui";
import { LyDialog } from "@alyle/ui/dialog";
import { ImgCropperEvent } from "@alyle/ui/image-cropper";
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewChild,
} from "@angular/core";
import { CropperDialogComponent } from "./cropper-dialog.component";
import {from, Observable} from "rxjs";

const STYLES = (theme: ThemeVariables, ref: ThemeRef) => {
	const __ = ref.selectorsOf(STYLES);
	return {
		$global: lyl`{
      body {
        background-color: ${theme.background.default}
        color: ${theme.text.default}
        font-family: ${theme.typography.fontFamily}
        margin: 0
        direction: ${theme.direction}

      }
    }`,
		root: lyl`{
      display: block
    }`,
	};
};

@Component({
	selector: "app-image-cropper",
	templateUrl: "./image-cropper.component.html",
	providers: [StyleRenderer],
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: ["button {padding: 0;  min-width: auto;}"],
  standalone:true
})
export class ImageCropperComponent {
	@Input() SizeX = 100;
	@Input() SizeY = 100;

	@Input() OutputType = 'base';
	@Input() round = false;
	@Input() resizableArea = false;


	@Output() OnSelectImage = new EventEmitter<any>();
	readonly classes = this.sRenderer.renderSheet(STYLES, true);

	@ViewChild('_fileInput') fileInput:any;

	cropped?: any;

	constructor(
		readonly sRenderer: StyleRenderer,
		private _dialog: LyDialog,
		private _cd: ChangeDetectorRef
	) { }

	openCropperDialog(event: Event) {
		this.cropped = null!;
		this._dialog
			.open<CropperDialogComponent>(CropperDialogComponent, {
				data: { event: event, config: { x: this.SizeX, y: this.SizeY, round:this.round,resizableArea:this.resizableArea } },
				width: 320,
				disableClose: true,
			})
			.afterClosed.subscribe((result?: ImgCropperEvent) => {
				if (result) {
					console.log("afterClosed",result)
					if(this.OutputType === 'base'){
						this.cropped = result.dataURL;
						this.OnSelectImage.emit(this.cropped);
						this._cd.markForCheck();
					} else{
						this.toBlob(result.dataURL).subscribe(res=>{
							this.cropped = res;
							this.OnSelectImage.emit(this.cropped);
							this._cd.markForCheck();
							return;
						})

					}
					//console.log("this.cropped",this.cropped)



				}
			});
	}




	toBlob(file: any): Observable<Blob> {
		return from(
			fetch(file)
				.then(res => res.blob())
				.then(blob => blob)
		);
	}

}
