<div class="partners-list__wrap" [ngClass]="{'block': isBlock}">
  <svg *ngIf="!flag && isSystem && role === 'company'" (click)="ShowSystemPartner()" class="partners-list__svg_tick"
       xmlns="http://www.w3.org/2000/svg" width="24" height="24"
       viewBox="0 0 24 24" fill="none">
    <g clip-path="url(#clip0_125_2599)">
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM15.88 8.29L10 14.17L8.12 12.29C7.73 11.9 7.1 11.9 6.71 12.29C6.32 12.68 6.32 13.31 6.71 13.7L9.3 16.29C9.69 16.68 10.32 16.68 10.71 16.29L17.3 9.7C17.69 9.31 17.69 8.68 17.3 8.29C16.91 7.9 16.27 7.9 15.88 8.29Z"
        fill="#26334D"/>
    </g>
    <defs>
      <clipPath id="clip0_125_2599">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
  <svg
    (click)="ShowSystemPartner()"
    *ngIf="flag && isSystem && role === 'company'" class="partners-list__svg_tick" xmlns="http://www.w3.org/2000/svg"
    width="24" height="24"
    viewBox="0 0 24 24" fill="none">
    <g clip-path="url(#clip0_125_2615)">
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
        fill="#18B97D"/>
    </g>
    <defs>
      <clipPath id="clip0_125_2615">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
  <app-preloader
    *ngIf="
    loadingService.States[
      loadingService.getIdFromArrayStateByType(
        loadingService.StatesNames.GET_PARTNER_IMAGE
      )
    ].value && LoadingImage;else img"></app-preloader>
  <ng-template #img>
    <div class="partners-list__img">
      <img class="object-fit" [src]="image">
    </div>
  </ng-template>
  <div class="partners-list__content" [ngClass]="{'block': isBlock}">
    <h3 class="partners-list__title">
      {{ name }}
    </h3>
    <h5 class="partners-list__caption">
      {{ bonus }}
    </h5>
    <div class="partners-list__text">
      <p>
        {{ description }}
      </p>
    </div>
    <div class="partners-list__tags">
      @for (item of tags;track $index){
        <span class="fr-tag">
        {{ item }}
      </span>
      }
    </div>


    <div class="partners-list__contacts" *ngIf="showContact">
      <app-preloader
        *ngIf="
    loadingService.States[
      loadingService.getIdFromArrayStateByType(
        loadingService.StatesNames.GET_PARTNER_CONTACT
      )
    ].value && ContactLoading;else contact"></app-preloader>
      <ng-template #contact>
        <a class="partners-list__phone" *ngIf="contacts !== null && !isLink(contacts)">
          {{ contacts }}
        </a>
        <button (click)="GetContact()" *ngIf="contacts === null"
                class="partners-list__btn partners-list__btn-contact btn-custom btn-active">
          Связаться
        </button>
        <button (click)="openLink(contacts)" *ngIf="contacts !== null && isLink(contacts)"
                class="partners-list__btn partners-list__btn-contact btn-custom btn-active">
          Открыть сайт
        </button>
      </ng-template>
    </div>

    <div class="partners-list__btns" *ngIf="!isSystem && role === 'company'">
      <button
        (click)="EditPartner()"
        class="partners-list__btn partners-list__btn_transparent btn-custom btn-active ">
        Редактировать
      </button>
      <svg (click)="DeletePartner()"
           class="partners-list__svg_remove" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
           viewBox="0 0 24 24" fill="none">
        <g clip-path="url(#clip0_125_2536)">
          <path
            d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM18 4H15.5L14.79 3.29C14.61 3.11 14.35 3 14.09 3H9.91C9.65 3 9.39 3.11 9.21 3.29L8.5 4H6C5.45 4 5 4.45 5 5C5 5.55 5.45 6 6 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4Z"
            fill="#E62E2E"/>
        </g>
        <defs>
          <clipPath id="clip0_125_2536">
            <rect width="24" height="24" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>

  </div>
</div>
