import { Component, ChangeDetectionStrategy, Inject, ViewChild, AfterViewInit } from '@angular/core';
import {StyleRenderer, WithStyles, lyl, ThemeRef, ThemeVariables, LyCommonModule} from '@alyle/ui';
import {LyDialogRef, LY_DIALOG_DATA, LyDialogModule} from '@alyle/ui/dialog';
import {LySliderModule, STYLES as SLIDER_STYLES} from '@alyle/ui/slider';
import {
  STYLES as CROPPER_STYLES,
  LyImageCropper,
  ImgCropperConfig,
  ImgCropperEvent,
  ImgCropperErrorEvent, LyImageCropperModule
} from '@alyle/ui/image-cropper';
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {LyButtonModule} from "@alyle/ui/button";
import {LyIconModule} from "@alyle/ui/icon";

const STYLES = (_theme: ThemeVariables, ref: ThemeRef) => {
  ref.renderStyleSheet(SLIDER_STYLES);
  ref.renderStyleSheet(CROPPER_STYLES);
  const slider = ref.selectorsOf(SLIDER_STYLES);
  const cropper = ref.selectorsOf(CROPPER_STYLES);

  return {
    root: lyl `{
      ${cropper.root} {
        max-width: 320px
        height: 320px
      }
    }`,
    sliderContainer: lyl `{
      position: relative
      ${slider.root} {
        width: 80%
        position: absolute
        left: 0
        right: 0
        margin: auto
        top: -32px
      }
    }`,
    slider: lyl `{
      padding: 1em
    }`
  };
};

@Component({
  templateUrl: './cropper-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    StyleRenderer
  ],
  standalone:true,
  imports: [
    CommonModule,
    LyImageCropperModule,
    LySliderModule,
    FormsModule,
    LyCommonModule,
    LyDialogModule,
    LyButtonModule,
    LyIconModule
  ]
})
export class CropperDialogComponent implements WithStyles, AfterViewInit {

  readonly classes = this.sRenderer.renderSheet(STYLES, 'root');
  ready: boolean = false;
  scale: any = 0;
  minScale: any = 0;
  @ViewChild(LyImageCropper, { static: true }) cropper: any = null;

  myConfig: ImgCropperConfig = {
    width: 215,
    height: 81,
    type: 'image/png',
    keepAspectRatio: false,
    //maxFileSize:5242880,
    responsiveArea: true,
    output: {
      width: 215,
      height: 81
    },
    resizableArea: false
  };

  constructor(
    @Inject(LY_DIALOG_DATA) private event: {event: Event, config: {x: number, y: number,round:boolean,resizableArea:boolean}},
    readonly sRenderer: StyleRenderer,
    public dialogRef: LyDialogRef
  ) {
      this.myConfig = {
        width: event.config.x,
        height: event.config.y,
        round:event.config.round,
        type: 'image/png',
        keepAspectRatio: false,//Пропорции
        responsiveArea: true,
        output: {
          width: event.config.x,
          height: event.config.y
        },
        resizableArea: event.config.resizableArea,

      };
   }

  ngAfterViewInit() {
    // Load image when dialog animation has finished
    this.dialogRef.afterOpened.subscribe(() => {
      this.cropper.selectInputEvent(this.event.event);
    });
  }

  onCropped(e: ImgCropperEvent) {
    //console.log('cropped img: ', e);
  }
  onLoaded(e: ImgCropperEvent) {
    //console.log('img loaded', e);
  }
  onError(e: ImgCropperErrorEvent) {
    // console.warn(`'${e.name}' is not a valid image`, e);
    // Close the dialog if it fails
    this.dialogRef.close();
  }

}
