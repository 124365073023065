
import {Component, EventEmitter, inject, Inject, Input, OnInit, Output, ViewChild,} from '@angular/core';
import { DownloadService } from 'src/app/shared/services/download.service';
import {DOCUMENT, NgIf} from "@angular/common";
import { LessonContentItemTextComponent } from './lesson-content-item-text/lesson-content-item-text.component';
import { LessonContentItemImageComponent } from './lesson-content-item-image/lesson-content-item-image.component';
import { LessonContentItemVideoComponent } from './lesson-content-item-video/lesson-content-item-video.component';
import { LessonContentItemFileComponent } from './lesson-content-item-file/lesson-content-item-file.component';
import { LessonContentItemAudioComponent } from './lesson-content-item-audio/lesson-content-item-audio.component';
import {LessonContentItemPartnerComponent} from "./lesson-content-item-partner/lesson-content-item-partner.component";
import {LoadingService} from "../../../../shared/services/loading.service";
import {HTTPService} from "../../../../shared/services/main.service";
import {
  LessonContentItemVideostreamComponent
} from "./lesson-content-item-videostream/lesson-content-item-videostream.component";

@Component({
  selector: 'app-lesson-content-item',
  templateUrl: './lesson-content-item.component.html',
  styleUrls: ['./lesson-content-item.component.scss'],
  imports: [
    NgIf,
    LessonContentItemTextComponent,
    LessonContentItemImageComponent,
    LessonContentItemFileComponent,
    LessonContentItemVideoComponent,
    LessonContentItemAudioComponent,
    LessonContentItemPartnerComponent,
    LessonContentItemVideostreamComponent
  ],
  standalone: true
})
export class LessonContentItemComponent implements OnInit {

  @Input() EditMode = false;
  mainService = inject(HTTPService)
  role: string = this.mainService.getFormattedProfileType();
  @Input() LessonId = 0;
  @Input() IsSelected = false;

  @Output() OnChanged = new EventEmitter<any>();
  @Output() OnChangedFile = new EventEmitter<any>();

  @Input() ItemUid: string = '';
  @Input() ItemType: string = '';
  @Input() ItemData: any = null;
  @Input() buttonName: any = null

  @ViewChild('item') Item: undefined |
    LessonContentItemTextComponent |
    LessonContentItemImageComponent |
    LessonContentItemVideoComponent |
    LessonContentItemFileComponent |
    LessonContentItemAudioComponent;


  hasUnsavedChanges = false;

  constructor(
      public loadingService: LoadingService,
      @Inject(DOCUMENT) document: Document
  ) {}

  ngOnInit(): void {
    if (this.role != 'company') {
      this.EditMode = false;
    }
    if(this.EditMode == false) {
      this.IsSelected = false;
    }
    //console.log("ngOnInit")

  }

  onChanged(event: any) {
    this.ItemData = event;
    //console.log("onChanged",this.ItemData);
    this.OnChanged.emit(this.ItemData);
  }
  onChangedFile(event: any){
    this.ItemData = event['data'];
    this.buttonName = event['buttonName'];
    //console.log("onChanged",this.ItemData);
    this.OnChangedFile.emit(event);
  }

  haveUnsaved() {
    return this.Item?.hasChanged ?? false;
  }



}
