import {ControlValueAccessor, NgControl} from "@angular/forms";
import {Directive} from "@angular/core";

@Directive({ selector: '[formControlTrim]',standalone:true })
export class InputRefDirective {
    constructor(private ngControl: NgControl) {
        trimValueAccessor(ngControl.valueAccessor)
    }
}

function trimValueAccessor(valueAccessor: ControlValueAccessor|null) {
    const original = valueAccessor!.registerOnChange;

    valueAccessor!.registerOnChange = (fn: (_: unknown) => void) => {
        return original.call(valueAccessor, (value: unknown) => {
            return fn(typeof value === 'string' ? value.trim() : value);
        });
    };
}
