<div class="image-block">

  <div *ngIf="IsLoading">
    <app-preloader></app-preloader>
  </div>
  <div *ngIf="!ImageUrl && !IsLoading" class="no-image-block">
    <p> Нет изображения!</p>

    <div *ngIf="IsSelected" class="file-wrapper" (click)="$event.stopPropagation()">
      <br>
      <input
        [id]="'image'+ItemUid"
        type="file"
        accept="image/png, image/jpeg"
        (change)="ChangeItemFile($event.target);"
      >
      <label [for]="'image'+ItemUid"><img src="assets/icons/download.svg">Загрузить изображение</label>
    </div>
  </div>

  <div class="image-wrapper" [ngClass]="{'edited': IsSelected}">
    <img class="image"
         [hidden]="!ImageUrl || IsLoading"
         [src]="ImageUrl"
         (load)="onLoad()"
         (error)="IsLoading = false"
         alt="Изображение"
    >

    <div class="replace" *ngIf="ImageUrl && !IsLoading && IsSelected" (click)="$event.stopPropagation()">
      <input
        [id]="'image'+ItemUid"
        type="file"
        style="display: none;"
        accept="image/png, image/jpeg"
        (change)="ChangeItemFile($event.target)"
      >
      <label [for]="'image'+ItemUid">
        <div class="replace-control">
          Загрузить изображение
        </div>
      </label>

    </div>
  </div>
  <!-- (click)="downloadImage();$event.stopPropagation()" -->
</div>
