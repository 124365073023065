import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { tap } from 'rxjs';
import { EducationService } from 'src/app/shared/services/education.service';
import { EventsService } from 'src/app/shared/services/events.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import {DomSanitizer} from "@angular/platform-browser";
import {saveAs} from "file-saver";
import {NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {PreloaderComponent} from "../../../../../shared/ui/preloader/preloader.component";
import {ConstantsService} from "../../../../../shared/services/constants.service";

@Component({
  selector: 'app-lesson-content-item-file',
  templateUrl: './lesson-content-item-file.component.html',
  styleUrls: ['./lesson-content-item-file.component.scss'],
  imports: [
    NgIf,
    PreloaderComponent,
    FormsModule
  ],
  standalone: true
})
export class LessonContentItemFileComponent implements OnInit {
  @Input() EditMode = false;
  @Input() IsSelected = false;

  @Output() OnChangedFile = new EventEmitter<any>();

  @Input() ItemUid: string = '';
  @Input() ItemType: string = '';
  @Input() ItemData: any = null;

  @Input() buttonName = '';
  buttonNameInit = '';

  NewFile:any = null;
  NewFileUrl:any = null;

  isLoading = true;
  isDownload = false;


  constructor(
    public loadingService: LoadingService,
    private educationService: EducationService,
    private eventsService: EventsService,
    private sanitizer: DomSanitizer,
    private constanstService: ConstantsService
  ) {}

  get hasChanged() { return (this.buttonNameInit != this.buttonName) || (this.NewFile != null) };


  ngOnInit(): void {
    this.isLoading = false;
    this.buttonNameInit = this.buttonName;
  }

  ChangeItemFile(files: any) {
    let size: number = +(files.files.item(0)['size'])
    if(size <= this.constanstService.FileMaxSize) {

        this.NewFile = files.files.item(0);
        console.log("this.NewFile",this.NewFile)

        this.NewFileUrl = this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(this.NewFile));

    } else{
        this.eventsService.throwError(`Размер файла не должен превышать ${this.constanstService.FileMaxSizeText}!`)
    }
  }

    Save(){
        const form = new FormData();
        if(this.NewFile){
            form.append('file', this.NewFile);
        }
        form.append('buttonName', String(this.buttonNameInit?this.buttonNameInit:""));
        this.isLoading = true;
        this.educationService.EditLessonContentData(this.ItemUid, form)
            .subscribe(
                (res) => {
                    //console.log("item = ", res);
                    if(this.NewFile){
                        this.ItemData = `/v1/lesson/template/data/${this.ItemUid}`;
                    }

                    this.OnChangedFile.emit({
                        data:this.ItemData,
                        buttonName: this.buttonNameInit
                    });
                    this.buttonName = this.buttonNameInit;
                    this.isLoading = false;
                    this.NewFile = null;
                    this.NewFileUrl = null;
                }, (err) => {
                    this.isLoading = false;
                }
            )



    }

  GetFile() {
    //console.log("GET FILE, ", this.ItemUid)
    this.isDownload = true;
    return this.educationService.GetLessonContentData(this.ItemUid)
        .pipe(
        tap((res) => {
          this.isDownload = false;
          //console.log("success download")
        })
    ).subscribe()
  }

     download() {
         saveAs(this.NewFile, this.NewFile['name']);
    }


}
