import { provideAnimations } from "@angular/platform-browser/animations";
import {ApplicationConfig, importProvidersFrom, LOCALE_ID} from '@angular/core';
import { provideRouter } from '@angular/router';

import {provideToastr} from "ngx-toastr";
import {HttpClientModule, provideHttpClient} from "@angular/common/http";
import {AppRoutes} from "./app.routes";
import {HAMMER_GESTURE_CONFIG} from "@angular/platform-browser";
import {LY_THEME, LY_THEME_NAME, LyHammerGestureConfig, LyTheme2, StyleRenderer} from "@alyle/ui";
import {MinimaLight} from "@alyle/ui/themes/minima";
import {provideCharts, withDefaultRegisterables} from "ng2-charts";
import {NeedReportDeactivateGuard} from "./shared/guards/need-report-deactivate-guard.service";
import {TemplateReportDeactivateGuard} from "./shared/guards/template-report-deactivate-guard.service";
import {PendingChangesGuard} from "./base/education/company/create-lesson/create-lesson.component";

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(AppRoutes),
    provideAnimations(), // required animations providers
    provideToastr(),
    provideCharts(withDefaultRegisterables()),
    provideHttpClient(),
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: LyHammerGestureConfig,
    },
    StyleRenderer,
    LyTheme2,
    { provide: LY_THEME_NAME, useValue: "minima-light" },
    { provide: LY_THEME, useClass: MinimaLight, multi: true },
    {provide:NeedReportDeactivateGuard},
    {provide:TemplateReportDeactivateGuard},
    {provide:PendingChangesGuard}
  ]
};
