import {Pipe, PipeTransform} from "@angular/core";
import {isValidLink} from "../validators/video.validators";

@Pipe({
  name: 'videoframePipe',
  standalone:true
})
export class VideoFramePipe implements PipeTransform {
  transform(url:any): any {
    const obj = isValidLink(url);
    if(obj.status){
      if(obj.origin === 'YOUTUBE'){
        if(obj.videoType.includes('shorts')){
          return  `<iframe width="315" style="max-width:100%" height="560" src="https://www.youtube.com/embed/${obj.id}" title="YouTube" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
        } else{
          return  `<iframe width="560" style="max-width:100%" height="315" src="https://www.youtube.com/embed/${obj.id}" title="YouTube" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
        }
      }

      if(obj.origin === 'RUTUBE'){
        const pswrd = obj.password == null || obj.password.length < 1 ? '' : `?p=${obj.password }` ;
        return  `<iframe width="560" style="max-width:100%" height="315" src="https://rutube.ru/play/embed/${obj.id}${pswrd}" title="RUTUBE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
      }
      return `<p style="color:red">Неверный формат видео</p>`

    } else{
      return `<p style="color:red">Неверный формат видео</p>`
    }

    return `<p style="color:red">Неверный формат видео</p>`

  }
}
