import {Injectable} from "@angular/core";
import {LoadingService} from "./loading.service";
import {HTTPService} from "./main.service";
import isEqual from 'lodash/isEqual';
import {tap} from "rxjs/operators";
import {map} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {DownloadService} from "./download.service";

@Injectable({
  providedIn: "root",
})
export class ReportsService {

  OldFranchiseList: any[] = [];
  FranchiseList: any[] = [];


  OldTemplate = null;
  CurrentTemplate = null;

  CompareTemplate() {
    return isEqual(this.OldTemplate, this.CurrentTemplate);
  }

  SetCurrentTemplate(obj: any) {
    this.CurrentTemplate = JSON.parse(JSON.stringify(obj));
  }

  SetOldTemplate(obj: any) {
    this.OldTemplate = JSON.parse(JSON.stringify(obj));
  }

  ResetTemplate() {
    this.OldTemplate = null;
    this.CurrentTemplate = null;
  }


  CompareFranchiseList() {
    return isEqual(this.OldFranchiseList, this.FranchiseList);
  }

  SetFranchiseList(list: any[]) {
    this.FranchiseList = JSON.parse(JSON.stringify(list));
  }

  ResetNeedFranchiseData() {
    //console.log("ResetNeedFranchiseData")
    this.OldFranchiseList = [];
    this.FranchiseList = [];
  }

  SetOldFranchiseList(list: any[]) {
    this.OldFranchiseList = JSON.parse(JSON.stringify(list));
  }


  constructor(
    private loadingService: LoadingService,
    private mainService: HTTPService,
    private http: HttpClient,
    private downloadService: DownloadService
  ) {
  }

  //Отправить отчет
  SendReport(month: number, year: number, data: any) {
    return this.mainService.SendPost(`/report/send?month=${month}&year=${year}`, data, this.loadingService.StatesNames.SEND_REPORT)

  }

  //Изменить статус отчета
  ChangeStatusReport(id: number, status: string) {
    return this.mainService.SendPatch(`/report/change-status/${id}?status=${status}`, {})

  }

  //Изменить шаблон отчета
  EditReportTemplate(data: any, type = this.loadingService.StatesNames.SAVE_REPORT_DATE) {
    return this.mainService.SendPatch('/report/template', data, type);

  }

  //Получить шаблон отчета
  GetReportTemplate() {
    return this.mainService.SendGet('/report/template', this.loadingService.StatesNames.GET_REPORT_TEMPLATE);

  }

  //Получить доступные даты для отправки отчетов
  GetAvailableDates() {
    return this.mainService.SendGet('/report/available-dates', this.loadingService.StatesNames.GET_AVAILABEL_DATE)

  }

  //Получить указанный отчет
  GetReportItem(id: number, type = this.loadingService.StatesNames.GET_REPORT_ITEM) {
    return this.mainService.SendGet(`/report/${id}`, type);

  }

  //Получить список отчетов
  GetReportList(filterString: string) {
    return this.mainService.SendGet(`/report/list` + filterString, this.loadingService.StatesNames.GET_REPORT_LIST);

  }

  //Получение файла


  //Меняет необходимость для франчайзи сдавать отчет
  ChangeNeedReport(data: any) {
    return this.mainService.SendPatch('/report/need-reports', data, this.loadingService.StatesNames.CHANGE_NEED_REPORT)

  }

  GetReportFile(uuid: string) {
    return this.mainService.SendDownloadGet(`/report/get-file/${uuid}`, this.loadingService.StatesNames.DOWNLOAD_FILE_REPORT);

  }

  HideBanner() {
    return this.mainService.SendPatch('/user/hide-banner', {})
  }


  //Получить версию отчета
  GetHistoryItem() {

  }

  //список версий отчета
  GetHistoryList() {

  }

  // GetImportExcel() {
  //   return this.mainService.SendGet('/report/import-excel', this.loadingService.StatesNames.GET_IMPORT_EXCEL)
  // }

  GetImportExcel() {
    return this.mainService.RequestHandler(() => {
      return this.http.get(`${this.mainService.ServerURL}` + `/report/import-excel`, {
        headers: this.mainService.headers,
        responseType: "blob" as "json",
        observe: "response",
      }).pipe(map((res: any) => {
        return this.downloadService.getResponse2File(res)
      }));
    }, this.loadingService.StatesNames.GET_IMPORT_EXCEL);
  }

}
