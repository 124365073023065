<app-preloader
        *ngIf="
    loadingService.States[
      loadingService.getIdFromArrayStateByType(
        loadingService.StatesNames.GLOBAL
      )
    ].value;else btn"></app-preloader>
<ng-template #btn>
    <router-outlet></router-outlet>
</ng-template>




