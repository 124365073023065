
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import {switchMap, takeUntil, tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {catchError, EMPTY, of, ReplaySubject} from "rxjs";
import { LessonContentItemComponent } from './lesson-content-item/lesson-content-item.component';
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {LoadingService} from "../../../shared/services/loading.service";
import {EducationService} from "../../../shared/services/education.service";
import {HTTPService} from "../../../shared/services/main.service";
import {ModalService} from "../../../_modal";
import {EventsService} from "../../../shared/services/events.service";

@Component({
  selector: 'app-lesson-content',
  templateUrl: './lesson-content.component.html',
  styleUrls: ['./lesson-content.component.scss'],
  imports: [
    NgIf,
    TooltipModule,
    NgForOf,
    NgClass,
    LessonContentItemComponent
  ],
  standalone: true

})
export class LessonContentComponent implements OnInit, OnDestroy {

  LessonContentLoaded = false;

  mainService = inject(HTTPService)
  role: string = this.mainService.getFormattedProfileType();

  @Input() EditMode = false;
  @Input() LessonId = 0;

  @ViewChildren(LessonContentItemComponent) Items: QueryList<LessonContentItemComponent> | undefined;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  Templates: any[] = [];
  Structure: any[] = [];

  InitStructure: any[] = [...this.Structure];

  SelectedItem: any = null;

  hasUnsavedChanges = false;
  isSaving = false;

  constructor(
      public loadingService: LoadingService,
      private educationService: EducationService,
      private router: Router,
      private modalService:ModalService,
      private eventsService: EventsService
  ) {}


  ngOnInit(): void {
    if (this.role != 'company') {
      this.EditMode = false;
    }
    if (this.LessonId !== 0){
      this.GetTemplateBlock().subscribe()
    }
  }

  GetTemplateBlock() {
    this.LessonContentLoaded = false;
    this.Templates = [];
    this.Structure = []
    //console.log("GET LESSON CONTENT: ", this.LessonId)
    return this.educationService.GetLessonContent(this.LessonId)
        .pipe(
        tap((res) => {
         //console.log(res);
         this.Templates = res;
         this.Structure = [...this.Templates];
         this.InitStructure = [...this.Structure];
         this.LessonContentLoaded = true;
        })
    )
  }

  makeStringRandomId() {
    const length = 90;
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return this.LessonId+result;
}

  // generateUUID() {
  //   var d = new Date().getTime();
  //   var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;
  //   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
  //       var r = Math.random() * 16;
  //       if(d > 0){
  //           r = (d + r)%16 | 0;
  //           d = Math.floor(d/16);
  //       } else {
  //           r = (d2 + r)%16 | 0;
  //           d2 = Math.floor(d2/16);
  //       }
  //       return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  //   });
  // }


  AddBlock(type: 'TEXT' | 'FILE' | 'IMAGE' | 'VIDEO'|'AUDIO' | 'PARTNER' | 'VIDEO_STREAM') {

   if(this.SelectedItem) {
       if(confirm('Вы уверены, что хотите изменить тип блока?')){
        for(let i = 0 ; i< this.Structure.length; i++) {
            for (let j = 0; j < this.Structure[i].length; j++) {
                if (this.Structure[i][j]['uuid'] == this.SelectedItem.uuid) {
                    this.Structure[i][j]['type'] = type;
                    this.Structure[i][j]['data'] = null;
                    this.Structure[i][j]['data'] = null;
                    //this.Structure[i][j]['buttonName'] = null;
                    break;
                }
            }
        }
           this.SaveBlocks();
    }
   } else {

           this.Structure.push(
               [
                   {
                       "uuid": this.makeStringRandomId(),
                       "type": type
                   }
               ]
           )

       this.SaveBlocks();
   }

   // this.SaveBlocks();

  }


  SaveBlocks() {
    this.hasUnsavedChanges = true;
    this.SelectedItem = null;
    this.Structure = this.Structure.map((el) => el.map((x: any) => {
        if(!Object.hasOwn(x, 'type')) {
          x.type = 'TEXT';
        }
       return x
    }))

    this.Structure = this.Structure.filter((el) => el.length > 0)

    let toDelete = (this.Templates.flat()
      .filter(
        ({ uuid: id1 }) => !this.Structure.flat().some(({ uuid: id2 }) => id2 === id1)
      )
    );

    this.isSaving = true;

    this.educationService.EditLessonContent(
      this.LessonId,
      {
        "update": this.Structure,
        "delete": toDelete.map(x => x.uuid)
      }
    ) .pipe(takeUntil(this.destroyed$)).subscribe(
      (res) => {
        //console.log(res);
        this.InitStructure = [...this.Structure];

        setTimeout(() => {
          this.hasUnsavedChanges = false;
          this.isSaving = false;
        }, 300);

        this.Templates = [...this.Structure]

      },
      (err) => {
        this.isSaving = false;
      }
    )
  }

  AddSplit(count = 1) {
    let arr = []
    for (let i=0; i< count; i++) {
      arr.push({ "uuid": this.makeStringRandomId(), "data": null})
    }

    this.Structure.push(arr)
    this.SaveBlocks();
  }

  SelectItem(item: any) {
    if(this.EditMode) {
      if(this.SelectedItem && item.uuid == this.SelectedItem.uuid) {
        this.SelectedItem = null
        return
      }
      this.SelectedItem = item;
    }
  }

  deleteItemClick(item: any) {
    //console.log("deleteItemClick", item)
    this.Structure = this.Structure.map((el) => el.filter((x: { uuid: any  }) => {
       return x.uuid != item.uuid
    }))
    this.SaveBlocks();
  }

    OpenLesson(){
      let host:any = window.location.href.split('?')[0].split('/')||'';
      host[5]='lesson-info';
      let url:string = host.join('/');
      // @ts-ignore
      window.open(url, '_blank').focus();
  }


  haveUnsaved() {
    return this.Items?.map((item)=>{
      return item.haveUnsaved()
    }).some(x => x == true) || this.hasUnsavedChanges;
  }

  saveMoveTimeout: any = null;
  upBlock(row: any, i: any) {
    if(i > 0) {
      this.swapRow(i, i-1);
      this.saveBlocksNotimmediately();
    }
  }
  downBlock(row: any, i: any) {
    if(i < this.Structure.length - 1) {
      this.swapRow(i, i+1);
      this.saveBlocksNotimmediately();
    }
  }
  swapRow(i: number, j: number) {
    let t =  this.Structure[i];
    this.Structure[i] =  this.Structure[j]
    this.Structure[j] = t;
  }
  saveBlocksNotimmediately() {
    clearTimeout(this.saveMoveTimeout);
    this.saveMoveTimeout = null;
    this.saveMoveTimeout = setTimeout(() => {
      this.SaveBlocks();
    }, 1400);
  }

  ngOnDestroy(): void {
    clearTimeout(this.saveMoveTimeout);
    this.saveMoveTimeout = null;
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
