<div class="page-header mb35">
    <h1 class="page-title f-title">
        Информация об уроке
        <div
          class="open"
          (click)="OpenLesson()"
          containerClass="customClass"
          tooltip="Открыть в режиме просмотра"
          [placement]="'bottom'"
          triggers="hover"
        >
            <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9979 0.666748C12.2526 0.666748 8.93369 2.62979 6.43791 4.72216C3.91793 6.83482 2.05338 9.23353 1.19186 10.4318C0.514171 11.3744 0.514172 12.6257 1.19186 13.5683C2.05338 14.7666 3.91793 17.1653 6.43791 19.278C8.93369 21.3704 12.2526 23.3334 15.9979 23.3334C19.7432 23.3334 23.0621 21.3704 25.5579 19.278C28.0779 17.1653 29.9424 14.7666 30.8039 13.5683C31.4816 12.6257 31.4816 11.3744 30.8039 10.4318C29.9424 9.23353 28.0779 6.83482 25.5579 4.72216C23.0621 2.62979 19.7432 0.666748 15.9979 0.666748ZM15.9985 19.0834C19.9106 19.0834 23.0819 15.9121 23.0819 12.0001C23.0819 8.08806 19.9106 4.91675 15.9985 4.91675C12.0865 4.91675 8.9152 8.08806 8.9152 12.0001C8.9152 15.9121 12.0865 19.0834 15.9985 19.0834Z" fill="white"/>
                <path d="M20.2485 12.0001C20.2485 14.3473 18.3457 16.2501 15.9985 16.2501C13.6513 16.2501 11.7485 14.3473 11.7485 12.0001C11.7485 9.65287 13.6513 7.75008 15.9985 7.75008C18.3457 7.75008 20.2485 9.65287 20.2485 12.0001Z" fill="white"/>
            </svg>
        </div>

    </h1>
</div>

<app-preloader
        *ngIf="
                loadingService.States[
                  loadingService.getIdFromArrayStateByType(
                    loadingService.StatesNames.GET_LESSON
                  )
                ].value;else course">
</app-preloader>


<ng-template #course>
    <form action="" [formGroup]="form">
        <div class="input-wrap">
            <label class="label">Название</label>
            <input
                    formControlTrim
                    formControlName="name"
                    class="form-input"
                    placeholder="Введите название"

            >
            <div class="count f-text" [ngClass]="{'error': form.get('name')!.hasError('maxlength')}">
                Кол-во символов
                {{form.get('name')!.value!.length +"/"+NameLength}}
            </div>
        </div>
        <div class="input-wrap">
            <app-preloader
                    *ngIf="
                            loadingService.States[
                              loadingService.getIdFromArrayStateByType(
                                loadingService.StatesNames.LESSON_ICON
                              )
                            ].value;else img"></app-preloader>
            <ng-template #img>
                <ng-container *ngIf="icon === null && iconPreview === null">
                    <label class="label">Выберите изображение для иконки</label>
                    <p class="hint">Рекомендовано: 700x700, max: 1050x1050, min: 350x350</p>
                    <div class="file-wrapper--button">
                        <app-image-cropper
                            #ImgCropper
                            [SizeX]="700"
                            [SizeY]="700"
                            [OutputType]="'blob'"
                            [round]="false"
                            (OnSelectImage)="handleFileInputIcon($event)"
                        >
                            <label class="label-file"><img src="assets/icons/download.svg">Загрузить изображение</label>
                        </app-image-cropper>

                    </div>
                </ng-container>
                <div class="current_image" *ngIf="iconPreview">
                    <label class="label">Текущая иконка</label>
                    <div class="img--wrap wrap--avatar">
                        <img class="preview" [src]="iconPreview"   >
                        <a  (click)="DeleteIcon()" *ngIf="icon!==null || iconPreview!==null">
                            <img src="assets/icons/delete.svg">
                        </a>
                    </div>
                    <!--<a (click)="OldImage()" *ngIf="image === null">Вернуть актуальное изображение</a>-->
                </div>
            </ng-template>
        </div>
        <div class="input-wrap">
            <app-preloader
                    *ngIf="
                            loadingService.States[
                              loadingService.getIdFromArrayStateByType(
                                loadingService.StatesNames.LESSON_HEADER
                              )
                            ].value;else coverImg"></app-preloader>
            <ng-template #coverImg>
                <ng-container *ngIf="header === null  && headerPreview === null">
                    <label class="label">Выберите изображение для обложки</label>
                    <p class="hint">Рекомендовано: 1920x360, max: 3840x720, min:960x180 ?</p>
                    <div class="file-wrapper--button">
                        <app-image-cropper
                            #ImgCropper
                            [SizeX]="1920"
                            [SizeY]="360"
                            [OutputType]="'blob'"
                            [round]="false"
                            (OnSelectImage)="handleFileInputHeader($event)"
                        >
                            <label class="label-file"><img src="assets/icons/download.svg">Загрузить изображение</label>
                        </app-image-cropper>
                    </div>
                </ng-container>
                <div class="current_image" *ngIf="headerPreview">
                    <label class="label">Текущая обложка</label>
                    <div class="img--wrap wrap--cover">

                        <img class="preview" [src]="headerPreview"   >
                        <a (click)="DeleteHeader()" *ngIf="header!==null || headerPreview!==null">
                            <img src="assets/icons/delete.svg" width="825" height="220">
                        </a>
                    </div>
                </div>
            </ng-template>
        </div>
        <!--<div class="input-wrap" *ngIf="LessonId === 0">
            <p>Тип триггера:{{triggerType}}</p>
            <p>Id прошлого урока:{{triggerValue}}</p>
        </div>-->
        <app-preloader
                *ngIf="
                loadingService.States[
                  loadingService.getIdFromArrayStateByType(
                    loadingService.StatesNames.CREATE_LESSON
                  )
                ].value;else btn">
        </app-preloader>
        <ng-template #btn>
            <div class="buttons">
                <div class="checkbox-wrap" *ngIf="LessonId!==0">
                    <input type="checkbox"
                           class="custom-checkbox"
                           [id]="'checkbox'"
                           [checked]="notification"
                           (change)="ChangeNotification()"
                    >
                    <label [for]="'checkbox'">Отправить уведомление об обновлении</label>
                </div>
                <button (click)="Send()" type="submit" class="btn-custom btn-active btn-xl" >{{LessonId===0?'Создать':'Сохранить'}}</button>
            </div>
        </ng-template>

    </form>
</ng-template>
