import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum LoadingStates {
  UNKNOWN = "UNKNOWN",
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  REGISTER = "REGISTER",
  REGISTER_VALIDATE = "REGISTER_VALIDATE",
  GLOBAL = "GLOBAL",
  CONFIRM = "CONFIRM",
  CREATE_NEWS = "CREATE_NEWS",
  NEWS_FILE = "NEWS_FILE",
  NEWS_LIST = "NEWS_LIST",
  NEWS_IMG = "NEWS_IMG",
  GET_NEWS ="GET_NEWS",
  DELETE_NEWS ="DELETE_NEWS",
  GET_TICKET = "GET_TICKET",
  GET_RESIPIENTS_TIKCKET = "GET_RESIPIENTS_TIKCKET",
  MSG_LIST = "MSG_LIST",
  MSG_FILE = "MSG_FILE",
  CREATE_TICKET = "CREATE_TICKET",
  TICKET_LIST = "TICKET_LIST",
  SEND_MSG = "SEND_MSG",
  SEND_DESIGN = "SEND_DESIGN",
  GET_DESIGN = "GET_DESIGN",
  GET_STUDENTS =  "GET_STUDENTS",
  CREATE_ROLE = "CREATE_ROLE",
  EDIT_ROLE = "EDIT_ROLE",
  DELETE_ROLE = "EDIT_ROLE",
  GET_ROLE = "GET_ROLE",
  GET_ROLES = "GET_ROLES",
  SEND_INVITE = "SEND_INVITE",
  GET_COURSES = "GET_COURSES",
  GET_COURSES_ITEM = "GET_COURSES_ITEM",
  CREATE_COURSE = "CREATE_COURSE",
  COURSE_AVATAR = "COURSE_AVATAR",
  CHANGE_NEED_REPORT = "CHANGE_NEED_REPORT",
  SAVE_REPORT_DATE = "SAVE_REPORT_DATE",
  SAVE_REPORT_FIELDS = "SAVE_REPORT_FIELDS",
  GET_REPORT_TEMPLATE = "GET_REPORT_TEMPLATE",
  GET_REPORT_LIST = "GET_REPORT_LIST",
  GET_REPORT_ITEM = "GET_REPORT_ITEM",
  DOWNLOAD_FILE_REPORT = "DOWNLOAD_FILE_REPORT",
  GET_AVAILABEL_DATE = "GET_AVAILABEL_DATE",
  SEND_REPORT = "SEND_REPORT",
  GET_BLOCKS = "GET_BLOCKS",
  GET_BLOCKS_UPDATE = "GET_BLOCKS_UPDATE",
  CREATE_BLOCK = "CREATE_BLOCK",
  GET_BLOCK_ITEM = "GET_BLOCK_ITEM",
  GET_BLOCK_AVATAR = "GET_BLOCK_AVATAR",
  ARCHIVE_BLOCK = "ARCHIVE_BLOCK",
  CREATE_LESSON = "CREATE_LESSON",
  GET_LESSONS = "GET_LESSONS",
  GET_LESSONS_UPDATE = "GET_LESSONS_UPDATE",
  LESSON_ICON = "LESSON_ICON",
  LESSON_HEADER = "LESSON_HEADER",
  GET_LESSON = "GET_LESSON",
  GET_TESTS = "GET_TESTS",
  EDIT_TESTS = "EDIT_TESTS",
  UPLOAD_TEST_FILE = "UPLOAD_TEST_FILE",
  UPDATE_TRIGGERS = "UPDATE_TRIGGERS",
  UPDATE_ORDERS = "UPDATE_ORDERS",
  SEND_TESTS = "SEND_TESTS",
  TEST_FILE = "TEST_FILE",
  GET_LESSON_CONTENT = "GET_LESSON_CONTENT",
  EDIT_LESSON_CONTENT = "EDIT_LESSON_CONTENT",
  GET_SENT_LIST = "GET_SENT_LIST",
  GET_SENT_ITEM = "GET_SENT_ITEM",
  CHECK_LESSON = "CHECK_LESSON",
  RESTART_LESSON = "RESTART_LESSON",
  CLONE_COURSE = "CLONE_COURSE",
  NOTIFICATION_LIST = "NOTIFICATION_LIST",
  SAVE_JOB = "SAVE_JOB",
  RECOVERY = "RECOVERY",
  RESET = "RESET",
  GET_AUDIO = "GET_AUDIO",
  GET_STORAGE_LIST = "GET_STORAGE_LIST",
  DELETE_STORAGE_ITEM = "DELETE_STORAGE_ITEM",
  UPLOAD_STORAGE_ITEM = "UPLOAD_STORAGE_ITEM",
  DOWNLOAD_STORAGE_ITEM = "DOWNLOAD_STORAGE_ITEM",
  GET_PARTNERS_LIST = "GET_PARTNERS_LIST",
  GET_PARTNERS_SHORT_LIST = "GET_PARTNERS_SHORT_LIST",
  GET_PARTNER_IMAGE = "GET_PARTNER_IMAGE",
  GET_PARTNER_CONTACT = "GET_PARTNER_CONTACT",
  ADD_PARTNER = "ADD_PARTNER",
  DELETE_PARTNER = "DELETE_PARTNER",
  ADD_GROUP = "ADD_GROUP",
  GET_GROUP = "GET_GROUP",
  DELETE_GROUP = "DELETE_GROUP",
  EDIT_FRANCHISE = "EDIT_FRANCHISE",
  GET_DASHBOARD_TABLE = "GET_DASHBOARD_TABLE",
  GET_DASHBOARD_GRAPH = "GET_DASHBOARD_GRAPH",
  GET_TEST_DATA = "GET_TEST_DATA",
  FRANCHISE_LIST = "FRANCHISE_LIST",
  GET_TEST_TARIFFS = "GET_TEST_TARIFFS",
  GET_PAYMENT= "GET_PAYMENT",
  PAY = "PAY",
  GET_TOKEN = "GET_TOKEN",
  SET_TOKEN = "SET_TOKEN",
  NEW_TASK = "NEW_TASK",
  GET_BOARD_STATUSES = "GET_BOARD_STATUSES",
  GET_TASK = "GET_TASK",
  GET_EXECUTORS = "GET_EXECUTORS",
  GET_TASKS = "GET_TASKS",
  ARCHIVE_TASK = "ARCHIVE_TASK",
  MOVE_TASK = "MOVE_TASK",
  GET_GUIDE_LIST = "GET_GUIDE_LIST",
  GET_STUDENTS_ITEM =  "GET_STUDENTS_ITEM",
  GET_STUDENTS_ITEM_HISTORY_OF_ACTIONS =  "GET_STUDENTS_ITEM_HISTORY_OF_ACTIONS",
  GET_STUDENTS_ITEM_FRANCHISE_ACTIONS =  "GET_STUDENTS_ITEM_FRANCHISE_ACTIONS",
  GET_STUDENTS_ITEM_STATISTICS_ALL =  "GET_STUDENTS_ITEM_STATISTICS_ALL",
  GET_STUDENTS_ITEM_STATISTICS_COURSE =  "GET_STUDENTS_ITEM_STATISTICS_COURSE",
  GET_COURSE_STATS_TOTAL =  "GET_COURSE_STATS_TOTAL",
  GET_COURSE_STATS =  "GET_COURSE_STATS",
  GET_STATS_COURSES =  "GET_STATS_COURSES",
  GET_STATS_COURSES_STUDENTS =  "GET_STATS_COURSES_STUDENTS",
  GET_STATS_COURSES_LESSONS =  "GET_STATS_COURSES_LESSONS",
  GET_STATS_COURSES_CARD =  "GET_STATS_COURSES_CARD",
  GET_FOLDER_LIST =  "GET_FOLDER_LIST",
  GET_FOLDER_LIST_TRANSFER =  "GET_FOLDER_LIST_TRANSFER",
  GET_FOLDER_LIST_CREATE_COURSE =  "GET_FOLDER_LIST_CREATE_COURSE",
  CREATE_FOLDER =  "CREATE_FOLDER",
  DELETE_FOLDER =  "DELETE_FOLDER",
  GET_COURSES_SHORT = 'GET_COURSES_SHORT',
  GET_BLOCKS_SHORT = 'GET_BLOCKS_SHORT',
  GET_COUNT_MEMBERS = 'GET_COUNT_MEMBERS',
  GET_FOLDER_FRANCHISES_LIST = 'GET_FOLDER_FRANCHISES_LIST',
  GET_FOLDER_FRANCHISES = 'GET_FOLDER_FRANCHISES',
  GET_FRANCHISE_FOLDERS_LIST = 'GET_FRANCHISE_FOLDERS_LIST',
  GET_FRANCHISE_FOLDERS = 'GET_FRANCHISE_FOLDERS',
  GET_VEBINARS = 'GET_VEBINARS',
  ADD_VEBINAR = 'ADD_VEBINAR',
  CLOSE_VEBINAR = 'CLOSE_VEBINAR',
  VEBINARS_DELETE = 'VEBINARS_DELETE',
  VEBINARS_EDIT = 'VEBINARS_EDIT',
  GET_BLOCKS_COPY = 'GET_BLOCKS_COPY',
  GET_HISTORY_TRANSLATIONS = 'GET_HISTORY_TRANSLATIONS',
  ADD_FRANCHISE_DAYS = 'ADD_FRANCHISE_DAYS',
  GET_NEWS_COMMENTS = 'GET_NEWS_COMMENTS',
  GET_NEWS_COMMENTS_FILE = 'GET_NEWS_COMMENTS_FILE',
  ADD_NEWS_COMMENTS = 'ADD_NEWS_COMMENTS',
  DELETE_NEWS_COMMENTS = 'DELETE_NEWS_COMMENTS',
  EDIT_NEWS_COMMENTS = 'EDIT_NEWS_COMMENTS',
  GET_IMPORT_EXCEL = 'GET_IMPORT_EXCEL',
  ADD_TASKS_TEMPLATE = 'ADD_TASKS_TEMPLATE',
  GET_EXECUTORS_LIST = 'GET_EXECUTORS_LIST',
  GET_TASKS_TEMPLATE_LIST = 'GET_TASKS_TEMPLATE_LIST',
  EDIT_TASKS_TEMPLATE = 'EDIT_TASKS_TEMPLATE',
  DELETE_TASKS_TEMPLATE = 'DELETE_TASKS_TEMPLATE',
  GET_TASKS_TEMPLATE_ITEM = 'GET_TASKS_TEMPLATE_ITEM',
}

export interface LoadingInterface {
  id: string;
  type: string;
}

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  constructor() {}

  StatesNames = LoadingStates;
  States = Object.keys(LoadingStates).map((x) => {
    return { state: x, value: false };
  });
  Loadings: LoadingInterface[] = [];
  IsLoadingChange$ = new Subject<boolean>();

  addLoading(requestType = LoadingStates.UNKNOWN): string {
    //console.log("\n\n")
    //console.log("STATES before adding = ", Object.assign({},this.States));

    const id = this.uuidv4();
    this.Loadings.push({
      id,
      type: requestType,
    });

    // console.warn( this.States, requestType);
    this.States.find((x) => x.state == requestType)!.value = true;

    //console.log("STATES afer adding = ", Object.assign({},this.States));
    //console.log("=== > Current Loadings { add } = ", Object.assign({},this.Loadings))
    this.IsLoadingChange$.next(true);
    //console.log("\n\n")
    return id;
  }

  removeLoading(id: string): LoadingInterface[] {
    //console.log("\n\n")
    //console.log("STATES before deleting = ", Object.assign({},this.States))
    const type = this.Loadings.find((x) => x.id == id)!.type;
    this.Loadings = this.Loadings.filter((f) => f.id != id);

    let countCurrentType = this.Loadings.filter((x) => x.type == type).length;
    this.States.find((x) => x.state == type)!.value =
      countCurrentType > 0 ? true : false;

    //console.log("STATES afer deleting = ", Object.assign({},this.States))
    //console.log("=== > Current Loadings { remove } = ", Object.assign({},this.Loadings))
    this.IsLoadingChange$.next(true);
    //console.log("\n\n")
    return this.Loadings;
  }

  isHasLoadingByType(requestType: LoadingStates) {
    return this.Loadings.findIndex((x) => x.type == requestType) > -1
      ? true
      : false;
  }

  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  getIdFromArrayStateByType(type: LoadingStates) {
    return this.States.findIndex((x) => x.state == type);
  }
}
