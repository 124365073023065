<div
  role="dialog"
  class="modal-container"
  #allscroll
  (click)="close()"
>
  <div
    class="modal-content"
    [@inOutAnimation]="isOpen"
    *ngIf="isOpen"
    (click)="$event.stopPropagation()"
  >
    <div class="modal-header" *ngIf="header">
      <ng-template *ngTemplateOutlet="header"></ng-template>
      <button type="button" class="modal-btn-close" (click)="close()">
        <svg class="modal-btn-close_svg" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M19.4978 7.74887C19.3235 7.5741 19.0867 7.47589 18.8398 7.47589C18.593 7.47589 18.3562 7.5741 18.1818 7.74887L13.6178 12.3035L9.05384 7.73954C8.87947 7.56477 8.64273 7.46655 8.39584 7.46655C8.14896 7.46655 7.91222 7.56477 7.73784 7.73954C7.37384 8.10354 7.37384 8.69154 7.73784 9.05554L12.3018 13.6195L7.73784 18.1835C7.37384 18.5475 7.37384 19.1355 7.73784 19.4995C8.10184 19.8635 8.68984 19.8635 9.05384 19.4995L13.6178 14.9355L18.1818 19.4995C18.5458 19.8635 19.1338 19.8635 19.4978 19.4995C19.8618 19.1355 19.8618 18.5475 19.4978 18.1835L14.9338 13.6195L19.4978 9.05554C19.8525 8.70087 19.8525 8.10354 19.4978 7.74887Z"
                fill="#E62E2E"/>
        </svg>
      </button>
    </div>
    <div class="modal-body">
      <ng-content select="[modal-content]"></ng-content>
    </div>
  </div>
</div>
