<!--<app-preloader *ngIf="loading"></app-preloader>-->

<audio  controls >
    <source [src]="src"  (error)="onError($event)">
    <p>{{src}}</p>
    <p>
        Ваш браузер не поддерживает HTML5 аудио.
        Вы можете <a [href]="src" target="_blank">скачать аудио</a>
    </p>
</audio>
