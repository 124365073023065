<div class="item">
  <div *ngIf="ItemType == 'TEXT'">
    <app-lesson-content-item-text #item [ItemUid]="ItemUid" [ItemData]="ItemData||''" [IsSelected]="IsSelected"
                                  (OnChanged)="onChanged($event)"></app-lesson-content-item-text>
  </div>

  <div *ngIf="ItemType == 'IMAGE'">
    <app-lesson-content-item-image #item [ItemUid]="ItemUid" [ItemData]="ItemData" [IsSelected]="IsSelected"
                                   (OnChanged)="onChanged($event)"></app-lesson-content-item-image>
  </div>

  <div *ngIf="ItemType == 'FILE'">
    <app-lesson-content-item-file #item
                                  [ItemUid]="ItemUid"
                                  [ItemData]="ItemData"
                                  [IsSelected]="IsSelected"
                                  (OnChangedFile)="onChangedFile($event)"
                                  [buttonName]="buttonName"

    ></app-lesson-content-item-file>
  </div>

  <div *ngIf="ItemType == 'VIDEO'">
    <app-lesson-content-item-video #item [ItemUid]="ItemUid" [ItemData]="ItemData" [IsSelected]="IsSelected"
                                   (OnChanged)="onChanged($event)"></app-lesson-content-item-video>
  </div>

  <div *ngIf="ItemType == 'AUDIO'">
    <app-lesson-content-item-audio #item
                                   [ItemUid]="ItemUid"
                                   [ItemData]="ItemData"
                                   [IsSelected]="IsSelected"
                                   [buttonName]="buttonName"
                                   (OnChangedFile)="onChangedFile($event)">
    </app-lesson-content-item-audio>
  </div>

  <div *ngIf="ItemType == 'PARTNER'">
    <app-lesson-content-item-partner #item
                                  [ItemUid]="ItemUid"
                                  [ItemData]="ItemData"
                                  [IsSelected]="IsSelected"
                                  [EditMode]="EditMode"
                                  (OnChanged)="onChanged($event)">
    </app-lesson-content-item-partner>
  </div>

  @defer (on viewport) {
    <div *ngIf="ItemType == 'VIDEO_STREAM'">
      <app-lesson-content-item-videostream #item
                                           [ItemUid]="ItemUid"
                                           [ItemData]="ItemData"
                                           [IsSelected]="IsSelected"
                                           (OnChangedFile)="onChangedFile($event)"

      >
      </app-lesson-content-item-videostream>
    </div>
  }
  @placeholder {
    <span>Загрузка...</span>
  }



</div>
