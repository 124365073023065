<div ly-dialog-content [lyPx]="0">

    <!--<div *ngIf="ready">
      <button (click)="cropper.zoomIn()" ly-button appearance="icon"><ly-icon>zoom_in</ly-icon></button>
      <button (click)="cropper.zoomOut()" ly-button appearance="icon"><ly-icon>zoom_out</ly-icon></button>
      <button (click)="cropper.center()" ly-button appearance="icon"><ly-icon>filter_center_focus</ly-icon></button>
      <button (click)="cropper.rotate(-90)" ly-button appearance="icon"><ly-icon>rotate_90_degrees_ccw</ly-icon></button>
      <button (click)="cropper.fit()" ly-button>В рамку</button>
      <button (click)="cropper.fitToScreen()" ly-button>На весь экран</button>
      <button (click)="cropper.setScale(1)" ly-button>1:1</button>
    </div>-->

    <ly-image-cropper
      [config]="myConfig"
      [(scale)]="scale"
      (ready)="ready = true"
      (cleaned)="ready = false"
      (minScale)="minScale = $event"
      (cropped)="onCropped($event)"
      (loaded)="onLoaded($event)"
      (error)="onError($event)"
    >
      <span>Перетащите изображение сюда...</span>
    </ly-image-cropper>

    <div *ngIf="ready" [className]="classes.sliderContainer">
      <div [class]="classes.slider">
        <ly-slider
          [thumbVisible]="false"
          [min]="minScale"
          [max]="1"
          [(ngModel)]="scale"
          (input)="scale = $event.value"
          step="0.000001"></ly-slider>
      </div>
    </div>

  </div>

  <div container *ngIf="ready" [lyJustifyContent]="'flex-end'" ly-dialog-actions>
    <button ly-button color="primary" (click)="dialogRef.close()">Отмена</button>
    <button ly-button color="primary" (click)="dialogRef.close(cropper.crop())">Ок</button>
  </div>
