import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import { tap } from 'rxjs';
import { EducationService } from 'src/app/shared/services/education.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import * as Editor from 'ckeditor5/build/ckeditor';
import {NgIf} from "@angular/common";
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {FormsModule} from "@angular/forms";
import {SafeHtml} from "../../../../../shared/pipes/SafeHtml";
import {LyDialog} from "@alyle/ui/dialog";
import {LyOverlay} from "@alyle/ui";


@Component({
  selector: 'app-lesson-content-item-text',
  templateUrl: './lesson-content-item-text.component.html',
  styleUrls: ['./lesson-content-item-text.component.scss'],
  imports: [
    NgIf,
    CKEditorModule,
    FormsModule,
    SafeHtml
  ],
  standalone: true,
  providers:[
    {provide:LyDialog},
    {provide:LyOverlay},

  ]
})
export class LessonContentItemTextComponent implements OnInit {


  public Editor1:any = Editor;
  config:any = {
    toolbar: [
        'heading', '|',
        'fontfamily', 'fontsize','fontColor','fontBackgroundColor',
        'alignment', '|',
        'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
        'link',
        'bulletedList', 'numberedList', '|',
        'insertTable', '|',
        'undo', 'redo',
        'removeFormat'
    ],

    heading: {
      options: [
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' }
      ]
    },
      fontSize:{
        options:
            Array.from({length: 65}, (_, i) => i + 8),

      },
      image: {
        // Configure the available styles.
        styles: [
          //'alignBlockLeft', 'alignCenter', 'alignBlockRight'
        ],

        // Configure the available image resize options.
        resizeOptions: [
          {
            name: 'resizeImage:original',
            label: 'Original',
            value: null
          },
          {
            name: 'resizeImage:50',
            label: '25%',
            value: '25'
          },
          {
            name: 'resizeImage:50',
            label: '50%',
            value: '50'
          },
          {
            name: 'resizeImage:75',
            label: '75%',
            value: '75'
          }
        ],

        // You need to configure the image toolbar, too, so it shows the new style
        // buttons as well as the resize buttons.
        toolbar: [
          'imageStyle:alignBlockLeft', 'imageStyle:alignCenter', 'imageStyle:alignBlockRight',
          '|',
          'ImageResize',
          '|',
          'imageTextAlternative'
        ]
      },
    table: {
      tableCellProperties: {
        defaultProperties: {
          verticalAlignment: 'center',
        }
      }
    },

      language: 'ru'
  };

  @Input() ItemUid: string = '';
  @Input() ItemData: any = null;
  @Input() IsSelected = false;


  @Output() OnChanged = new EventEmitter<any>();

  InitText: any = null;

  constructor(
    public loadingService: LoadingService,
    private educationService: EducationService,

  ) {

  }

  ngOnInit(): void {
    this.InitText = this.ItemData;

  }

  get hasChanged() { return this.InitText != this.ItemData };

  changeText() {
    this.ChangeItem().subscribe()
  }

  cancelChangeText () {
    this.ItemData = this.InitText;
    this.OnChanged.emit(this.ItemData)
  }

  ChangeItem() {
    const form = new FormData();
    form.append('content', String(this.ItemData));

    return this.educationService.EditLessonContentData(this.ItemUid, form)
        .pipe(
          tap((res) => {
            this.InitText = this.ItemData;
            this.OnChanged.emit(this.ItemData)
          })
    )
  }

  ResetFocus(e:any){
    //console.log("ResetFocus",e)

  }


}
