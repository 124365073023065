import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {tap} from "rxjs/operators";
import {FormControl, FormGroup, FormsModule, isFormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Subject, takeUntil} from "rxjs";
import {saveAs} from "file-saver";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {NgToggleModule} from "ng-toggle-button";
import {OpenTestComponent} from "./open-test/open-test.component";
import {CloseTestComponent} from "./close-test/close-test.component";
import {PreloaderComponent} from "../../../../shared/ui/preloader/preloader.component";
import {EducationService} from "../../../../shared/services/education.service";
import {LoadingService} from "../../../../shared/services/loading.service";
import {EventsService} from "../../../../shared/services/events.service";


@Component({
  selector: 'app-test-constructor',
  templateUrl: './test-constructor.component.html',
  styleUrls: ['./test-constructor.component.scss'],
  imports: [
    TooltipModule,
    PreloaderComponent,
    NgClass,
    NgToggleModule,
    NgIf,
    ReactiveFormsModule,
    OpenTestComponent,
    CloseTestComponent,
    FormsModule,
    NgForOf
  ],
  standalone: true
})
export class TestConstructorComponent implements  OnInit, OnDestroy{
  @Input() LessonId = 0;
  TestList:any[] = []
  Value = false;
  DownloadId = 0;


  EmptyQuestionList:any[] = [];

  maxQuestionId = 0;

  hasUnsavedChanges = false;

  _destroy$= new Subject();

  form = new FormGroup({
    limitErrors: new FormControl(0,[Validators.min(0),Validators.required]),
    testCheckNeeded: new FormControl(true),

  });

  constructor(
      private educationService: EducationService,
      public loadingService: LoadingService,
      private eventsService: EventsService
  ) {
  }

  ngOnInit(): void {
    if(this.LessonId !==0){
      this.GetTestsBlock().subscribe()
    }

  }


  GetTestsBlock(){
    return this.educationService.GetTests(this.LessonId)
        .pipe(
        tap((res)=>{
          this.TestList = res['questions'];
          const arr:number[] = this.TestList.map((item:any)=>item['id']);
          this.form.get('limitErrors')?.setValue(res['limitErrors']);
          this.form.get('testCheckNeeded')?.setValue(res['testCheckNeeded']);
          this.maxQuestionId = arr.length>0? Math.max.apply(Math, arr):1;
          this.InitFormSubscribe();

          for(let item of this.TestList){
              if(!item['open']){
                if( item['answers'].length>0){
                  const arr1 = item['answers'].map((ex:any)=>ex['id'])
                  item['maxId'] = arr1.length>0? Math.max.apply(Math, arr1):1;
                }

              }
          }
            this.Value = this.TestList.length>0?true:false;



        })
        )

  }

  InitFormSubscribe(){
    this.form.get('limitErrors')?.valueChanges
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe({next:()=>{
        this.hasUnsavedChanges = true;
      }})
    this.form.get('testCheckNeeded')?.valueChanges
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe({next:()=>{
        this.hasUnsavedChanges = true;
      }})

  }

  DeleteQuestion({id}:any){
    //console.log("DeleteQuestion",id);
    this.TestList = this.TestList.filter(item=>item['id']!==id);
    this.hasUnsavedChanges = true;

  }

  ChangeQuestionText({id,question}:any){
    const item = this.TestList.find(ex=>ex['id']===id);
    item['question'] = question;
    item['error'] = item['question'] === ''?true:false;
    this.hasUnsavedChanges = true;
  }

  AddVariant({id,variantId,right}:any){
    const item = this.TestList.find(ex=>ex['id']===id);
    item['maxId'] = variantId;
    item['answers'].push(
        {
          id:variantId,
          right: right,
          text: ''

        }
    )
    this.hasUnsavedChanges = true;

  }

  DeleteVariant({id,variantId}:any){
    const item = this.TestList.find(ex=>ex['id']===id);
    if(item['answers']['length']>0){
      item['answers'][0]['right'] = true;
    }
    item['answers'] = item['answers'].filter((ex:any)=>ex['id']!==variantId);
    this.hasUnsavedChanges = true;
  }

  ChangeVariant({id,item}:any){
    const question = this.TestList.find(obj=>obj['id']===id);
    let variant = question['answers'].find((ex:any)=>ex['id'] === item['id']);
    variant = item;
    this.hasUnsavedChanges = true;

  }

  AddOpenQuestion(){
    const id = this.maxQuestionId + 1;
    this.maxQuestionId = id;
    this.TestList.push({
      answers: [],
      id: id,
      open:true,
      question: ""
    })
    //console.log("TestList",this.TestList)
    this.hasUnsavedChanges = true;
  }

  AddCloseQuestion(){
    const id = this.maxQuestionId + 1;
    this.maxQuestionId = id;
    this.TestList.push({
      answers: [
        {
          id:1,
          right: true,
          text: ''

        },
        {
          id:2,
          right: false,
          text: ''

        },
      ],
      id: id,
      open:false,
      question: "",
      maxId: 2
    })
    //console.log("TestList",this.TestList)
    this.hasUnsavedChanges = true;
  }

  SaveTests(){
    this.eventsService.resetAllEvents();
    const text = this.ValidateEmptyTextBeforeSend();
    const variant = this.ValidateEmptyTextVariantBeforeSend();
    const emptyVariant = this.ValidateVariantEmptyList();
    for (const i in this.form.controls) {
      this.form.get(i)?.updateValueAndValidity();
    }
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
    const valid = this.form.valid;

    if(text && variant && emptyVariant && valid && this.ValidateHasRightQuestion()){
      let data =
        {
          ...this.form.getRawValue(),
          questions:this.TestList
        }
        if(!this.Value){
          data.questions = [];
          data.testCheckNeeded = true;
          data.limitErrors = 0;
        }
      this.educationService.EditTests(this.LessonId,data)
          .subscribe(()=>{
            this.eventsService.success('Задание успешно сохранено!')
            this.hasUnsavedChanges = false;

          })

    }

  }



  ValidateEmptyTextBeforeSend(){
    let flag = true;
    for (let item of this.TestList){
      if(item['question'] === ''){
        item['error']=true;
        flag = false;
      }

    }
    if(!flag){
      this.eventsService.throwError('Текст вопроса не может быть пустым!');
    }
    return  flag;

  }

  ValidateEmptyTextVariantBeforeSend(){
    let flag = true;
    for (let item of this.TestList){
      if(!item['open']){
        for(let ex  of item['answers']){
          if(ex['text'] === ''){
            flag = false;
            ex['error'] = true;
          }
        }


      }

    }
    if(!flag){
      this.eventsService.throwError('Текст варианта ответа не может быть пустым!');
    }
    return  flag;

  }

  ValidateVariantEmptyList() {
    for (let item of this.TestList) {
      if (!item['open']) {
        if (item['answers'].length < 1) {
          this.eventsService.throwError('Список вариантов ответов не может быть пустым!')
          return false;
        }
      }
    }
    return true;
  }

  ValidateHasRightQuestion() {
    for (let item of this.TestList) {
      if (!item['open']) {
        if (item['answers'].filter((ex:any)=>ex['right']).length< 1) {
          this.eventsService.throwError('В вопросе должен быть хотя бы один правильный вариант ответа!')
          return false;
        }
      }
    }
    return true;
  }

  haveUnsaved() {
    return this.hasUnsavedChanges;
  }

  OpenLesson(){
    let host:any = window.location.href.split('?')[0].split('/')||'';
    host[5]='lesson-info';
    let url:string = host.join('/');
    // @ts-ignore
    window.open(url, '_blank').focus();
  }


  UploadFile(id:number,file:File){
    const question = this.TestList.find(obj=>obj['id']===id);
    const form = new FormData();
    form.append('file',file)
    this.educationService.UploadTestFile(form).subscribe({
      next:(res)=>{
        //console.log("res",res)
        question['attachedFile'] = {};
        question['attachedFile']['fileId'] = res['id'];
        question['attachedFile']['filename'] = file.name;
        /*console.log("question",question);
        console.log("test",this.TestList);*/
        this.haveUnsaved();
      }
    })

  }

  DeleteFile(id:number){
    const question = this.TestList.find(obj=>obj['id']===id);
    question['attachedFile'] = {};
    question['attachedFile']['fileId'] = -1;
    question['attachedFile']['filename'] = null;
    this.haveUnsaved();
  }

  DownloadFile(id:number,{fileId,fileName}:any){
    this.DownloadId = id;
    this.educationService.GetTestFile(fileId).subscribe({
      next: (res)=>{
        saveAs(res, fileName);
      }
    })
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete()
  }








}
