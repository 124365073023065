import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable, of, switchMap} from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {ConfirmModalComponent} from "../ui/confirm-modal/confirm-modal.component";
import {ModalService} from "../../_modal";
import {ReportsService} from "../services/reports.service";
import {SaveModalComponent} from "../ui/save-modal/save-modal.component";


@Injectable()
export class TemplateReportDeactivateGuard  {
    constructor(private modalService: ModalService, private reportsService: ReportsService, private router:Router) { }
    canDeactivate(
        component: any,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        //console.log('can exit');
        const logoutRegEx = /auth\/login/gi;
        //skip login url (onLogout)
        if (logoutRegEx.test(nextState!.url)) {
            return true;
        }
        //console.log('can exit', this.reportsService.CompareFranchiseList());
        return this.reportsService.CompareTemplate() ? true :
            this.modalService.open(SaveModalComponent,
                { title: 'Сохранение', msg: "Данные не будут сохранены. Продолжить переход?"
                }).onResult()
                .pipe(
                    switchMap(() =>{
                        this.reportsService.ResetTemplate()
                        return of(true);
                    } ),
                    catchError(() => {
                        return of(false);
                    }))
    }

}
