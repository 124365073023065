<div class="test-wrap">
  <p>Введите текст вопроса</p>
  <div class="content">
    <!--<textarea [(ngModel)]="question"
              (ngModelChange)="ChangeQuestionText()"
              [ngClass]="{'error-msg': ErrorText}"
              class="form-input"
              placeholder="Введите текст вопрос"
    ></textarea>-->
    <ckeditor
      [editor]="Editor1"
      [config]="config"
      [(ngModel)]="question"
      (ngModelChange)="ChangeQuestionText()"></ckeditor>
    <a class="delete" (click)="DeleteTest()">
      <img src="assets/icons/blocks/delete.svg">
    </a>
  </div>
  <div class="input-wrap">
    <p>Файл</p>
    <app-preloader
      *ngIf="
            loadingService.States[
              loadingService.getIdFromArrayStateByType(
                loadingService.StatesNames.UPLOAD_TEST_FILE
              )
            ].value;else fileUpload"></app-preloader>
    <ng-template #fileUpload>
      <div
        class="file-wrapper--button"
        *ngIf="!FileName"
        (click)="$event.stopPropagation()"
      >
        <input
          [id]="'file'+id"
          type="file"
          (change)="UploadFile($event.target)"
        >
        <label [for]="'file'+id" class="label-file">
          <div class="label-content">
            <img src="assets/icons/download.svg"> Загрузить файл
          </div>
        </label>
      </div>
    </ng-template>
    <app-preloader
      *ngIf="
            DownloadId===id && loadingService.States[
              loadingService.getIdFromArrayStateByType(
                loadingService.StatesNames.TEST_FILE
              )
            ].value;else fileDownload"></app-preloader>
    <ng-template #fileDownload>
      <div class="file-preview" *ngIf="FileName">
        <button type="button" class="btn-custom  btn-active"
                (click)="DownloadFile()">
          {{ FileName || 'Скачать файл' }}
        </button>
        <a type="button" (click)="DeleteFile()"><img src="assets/icons/delete.svg"></a>
      </div>
    </ng-template>
  </div>
  <div class="questions">
    <p>Впишите варианты ответов и отметьте правильный</p>
    <div class="item" *ngFor="let item of answers">
      <div class="input-wrap-checkbox input-wrap">
        <label class="container-check">
          <input
            type="checkbox"
            [checked]="item['right']"
            [name]="'variant'+id"
            (change)="ChangeRightAnswer(item,$event)"
          >
          <span class="checkmark"></span>
        </label>
      </div>
      <textarea
        [ngClass]="{'error-msg':item['error']}"
        [(ngModel)]="item['text']"
        (ngModelChange)="ChangeAnswers(item)"
        class="form-input" placeholder="Введите вариант ответа"></textarea>
      <a class="delete" (click)="DeleteVariant(item['id'])">
        <img src="assets/icons/blocks/delete.svg">
      </a>

    </div>
    <a class="add-variant" (click)="AddVariant()">
      <div class="svg-wrap left">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M11 5.5C10.395 5.5 9.9 5.995 9.9 6.6V9.9H6.6C5.995 9.9 5.5 10.395 5.5 11C5.5 11.605 5.995 12.1 6.6 12.1H9.9V15.4C9.9 16.005 10.395 16.5 11 16.5C11.605 16.5 12.1 16.005 12.1 15.4V12.1H15.4C16.005 12.1 16.5 11.605 16.5 11C16.5 10.395 16.005 9.9 15.4 9.9H12.1V6.6C12.1 5.995 11.605 5.5 11 5.5ZM11 0C4.928 0 0 4.928 0 11C0 17.072 4.928 22 11 22C17.072 22 22 17.072 22 11C22 4.928 17.072 0 11 0ZM11.0031 19.8015C6.15212 19.8015 2.20312 15.8525 2.20312 11.0015C2.20312 6.15051 6.15212 2.20151 11.0031 2.20151C15.8541 2.20151 19.8031 6.15051 19.8031 11.0015C19.8031 15.8525 15.8541 19.8015 11.0031 19.8015Z"
                fill="#FF6633"/>
        </svg>

      </div>
      Добавить вариант ответа
    </a>
  </div>

</div>
