<p class="not-saved-block" *ngIf="hasChanged">Изменения не сохранены</p>
<div *ngIf="isLoadingAudio">
  <app-preloader></app-preloader>
</div>

<div *ngIf="!isLoadingAudio">
  <div class="content-wrapper" *ngIf="ItemData || NewFile">
    <div (click)="Play($event)" class="download-button" *ngIf="!IsPlay">
      <span *ngIf="buttonNameInit">{{buttonNameInit}}</span>
      <div class="play-icon">
        <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M3.08167 23.0976L17.2982 13.1568C18.1245 12.6065 18.1245 11.3936 17.2982 10.8433L3.08167 0.902572C2.1568 0.286654 0.917969 0.948944 0.917969 2.05931V21.9409C0.917969 23.0512 2.1568 23.7135 3.08167 23.0976Z"
                fill="white"/>
        </svg>

      </div>
    </div>

    <div *ngIf="isLoadingAudio;else audio">
      <app-preloader></app-preloader>
    </div>
    <ng-template #audio>
      <div *ngIf="IsPlay" style="width: 100%;">
        <app-audio-player *ngIf="!NewFile" [src]="src"></app-audio-player>
        <app-audio-player *ngIf="NewFile" [src]="NewFileUrl"></app-audio-player>
        <button *ngIf="IsSelected" class="btn-custom btn-active to-text" (click)="ToText($event)">К подписи</button>
      </div>
    </ng-template>
    <!--<app-preloader *ngIf="isDownload"></app-preloader>-->
  </div>

  <p class="no-file" *ngIf="!ItemData && !NewFile">Нет аудио</p>

  <div *ngIf="IsSelected">
    <br>
    <div class="name" (click)="$event.stopPropagation()">
      <input class="form-input" [(ngModel)]="buttonNameInit" placeholder="Введите название кнопки">
    </div>
    <p class="audio-text f-text">Форматы: wav, mp3, ogg, m4a</p>
    <div class="file-wrapper" (click)="$event.stopPropagation()">
      <input
        [id]="'file'+ItemUid"
        type="file"
        accept="audio/mp3, audio/wav, audio/ogg, audio/x-m4a"
        (change)="ChangeItemFile($event.target)"
      >
      <label [for]="'file'+ItemUid">
        <div class="label-content">
          <img src="assets/icons/download.svg"> Загрузить аудио
        </div>
      </label>
    </div>
    <div class="buttons" (click)="Save()">
      <button class="btn-custom btn-active btn-xl">Сохранить</button>
    </div>
  </div>
</div>
