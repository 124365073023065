
<p class="not-saved-block" *ngIf="(NewFile!==null && NewFile != ItemData) || buttonName!==buttonNameInit">Изменения не сохранены</p>
<div *ngIf="isLoading"><app-preloader></app-preloader></div>

<div *ngIf="!isLoading">
    <div class="content-wrapper" *ngIf="ItemData || NewFile">
        <div *ngIf="!NewFile" class="download-button" (click)="GetFile();$event.stopPropagation()">
            {{buttonNameInit||'Скачать файл'}}
        </div>
        <div *ngIf="NewFile" class="download-button" (click)="download();$event.stopPropagation()">
            {{buttonNameInit||'Скачать файл'}}
        </div>
        <app-preloader *ngIf="isDownload"></app-preloader>
    </div>

    <p class="no-file" *ngIf="!ItemData && !NewFile">Нет файла</p>

    <div *ngIf="IsSelected">
        <br>
        <div class="name" (click)="$event.stopPropagation()">
            <input class="form-input" [(ngModel)]="buttonNameInit" placeholder="Введите название кнопки">
        </div>
        <div class="file-wrapper" (click)="$event.stopPropagation()">
            <input
                    [id]="'file'+ItemUid"
                    type="file"
                    (change)="ChangeItemFile($event.target)"
            >
            <label [for]="'file'+ItemUid">
                <div class="label-content">
                    <img src="assets/icons/download.svg"> Загрузить файл
                </div>
            </label>
        </div>
        <div class="buttons" (click)="Save()">
            <button class="btn-custom btn-active btn-xl">Сохранить</button>
        </div>
    </div>
</div>
