import {Component, OnDestroy, OnInit} from '@angular/core';
import {EventsService} from "./shared/services/events.service";
import {ToastrService} from "ngx-toastr";
import {Subject, takeUntil} from "rxjs";
import {HTTPService} from "./shared/services/main.service";
import {LoadingService} from "./shared/services/loading.service";
import {lyl, ThemeRef, ThemeVariables} from "@alyle/ui";
import {PreloaderComponent} from "./shared/ui/preloader/preloader.component";
import {NgIf} from "@angular/common";
import {RouterOutlet} from "@angular/router";


const STYLES = (theme: ThemeVariables, ref: ThemeRef) => {
    const __ = ref.selectorsOf(STYLES);
    return {
        $global: lyl`{
      body {
        background-color: ${theme.background.default}
        color: ${theme.text.default}
        font-family: ${theme.typography.fontFamily}
        margin: 0
        direction: ${theme.direction}
      }
    }`,
        root: lyl`{
      display: block
    }`,
    };
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    PreloaderComponent,
    NgIf
  ]
})
export class AppComponent implements OnInit, OnDestroy{

    private destroy$ = new Subject();


  constructor(private events: EventsService,
              private toastr: ToastrService,
              private main: HTTPService,
              public loadingService:LoadingService
              ) {
  }
  ngOnInit(): void {
        this.events.IsErrorChange$
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe((res) => {
          if (res && this.events.Error.status) {
            this.toastr.error(this.events.Error.text, "Ошибка!", {
              positionClass: "toast-bottom-center",
              titleClass: 'title-toast'

            });
          } else {
            this.toastr.clear();
          }
        })

        this.events.IsSuccessChange$
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe((res) => {
          if (res && this.events.Success.status) {
            this.toastr.success(
                this.events.Success.text,
                this.events.Success.title ?? "Операция выполнена успешно",
                {
                  positionClass: "toast-bottom-center",
                  titleClass: 'title-toast'
                }
            );
          } else {
            this.toastr.clear();
          }
        })
      this.main.CheckToken();
      this.main.GetCompanyDictionary().subscribe();

  }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

}
