import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { tap } from 'rxjs';
import { EducationService } from 'src/app/shared/services/education.service';
import { EventsService } from 'src/app/shared/services/events.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import {NgClass, NgIf} from "@angular/common";
import {PreloaderComponent} from "../../../../../shared/ui/preloader/preloader.component";
import {ConstantsService} from "../../../../../shared/services/constants.service";
import {LyDialog} from "@alyle/ui/dialog";
import {LyOverlay} from "@alyle/ui";

@Component({
  selector: 'app-lesson-content-item-image',
  templateUrl: './lesson-content-item-image.component.html',
  styleUrls: ['./lesson-content-item-image.component.scss'],
  imports: [
    NgIf,
    PreloaderComponent,
    NgClass
  ],
  standalone: true,
  providers:[
    {provide:LyDialog},
    {provide:LyOverlay},

  ]
})
export class LessonContentItemImageComponent implements OnInit {

  @Input() EditMode = false;
  @Input() IsSelected = false;

  @Output() OnChanged = new EventEmitter<any>();

  @Input() ItemUid: string = '';
  @Input() ItemType: string = '';
  @Input() ItemData: any = null;

  ImageUrl = ''
  IsLoading = true;

  InitData: any = null;

  constructor(
    public loadingService: LoadingService,
    private educationService: EducationService,
    private eventsService: EventsService,
    private constantsService: ConstantsService
) {}

  get hasChanged() { return this.InitData != this.ItemData };

  ngOnInit() {
    this.InitData = this.ItemData;
    if(this.ItemData) {
      this.GetImage().subscribe()
    } else {
      this.IsLoading = false;
    }

  }

  GetImage() {
    return this.educationService.GetLessonContentImage(this.ItemUid)
        .pipe(
        tap((res) => {
          this.ImageUrl = res;
          this.InitData = this.ItemData;

        })
    )
  }

  ChangeItemFile(files: any) {
    let size: number = +(files.files.item(0)['size'])
    if(size <= this.constantsService.FileMaxSize) {
        let sendFile = files.files.item(0);
        if(sendFile){
          const form = new FormData();
          form.append('file', sendFile);
          this.educationService.EditLessonContentData(this.ItemUid, form)
          .subscribe(
            (res) => {
              this.IsLoading = true;
              this.ItemData = `/v1/lesson/template/data/${this.ItemUid}`;
              this.OnChanged.emit(this.ItemData);
              this.GetImage().subscribe()
            }
          )
        }
    } else{
        this.eventsService.throwError(`Размер файла не должен превышать ${this.constantsService.FileMaxSize}!`)
    }
  }

  onLoad() {
    setTimeout(() => {
      this.IsLoading = false;
    }, 3000);

  }

  downloadImage() {
    return this.educationService.GetLessonContentData(this.ItemUid)
    .pipe(
    tap((res) => {
      //console.log("success download")
    })
    ).subscribe()
  }

  // TODO
  deleteImage() {
    const form = new FormData();
      form.append('file', new Blob());
      this.educationService.EditLessonContentData(this.ItemUid, form)
      .subscribe(
        (res) => {
          this.GetImage().subscribe()
        }
      )
  }

}

