import { Component } from '@angular/core';
import {Modal} from "../../../_modal/modal-options";
import {LoadingService} from "../../services/loading.service";
import {ModalModule} from "../../../_modal";
import {PreloaderComponent} from "../preloader/preloader.component";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-save-modal',
  templateUrl: './save-modal.component.html',
  styleUrls: ['./save-modal.component.scss'],
  imports: [
    ModalModule,
    PreloaderComponent,
    NgIf
  ],
  standalone: true
})
export class SaveModalComponent extends Modal {

  constructor(public loadingService:LoadingService){
    super();
  }
  title: string = '';
  msg:string = '';

  onInjectInputs(inputs:any): void {
    this.title = inputs['title'];
    this.msg = inputs['msg'];

  }

  Send(){
    this.send();

  }

}
