import {Component, EventEmitter, Input, OnChanges, OnInit, Output, Sanitizer, SimpleChanges} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {OnChange} from "ngx-bootstrap/utils";
import {NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {PreloaderComponent} from "../../../../../shared/ui/preloader/preloader.component";
import {AudioPlayerComponent} from "../../../../../shared/ui/audio-player/audio-player.component";
import {LoadingService} from "../../../../../shared/services/loading.service";
import {EducationService} from "../../../../../shared/services/education.service";
import {EventsService} from "../../../../../shared/services/events.service";
import {ConstantsService} from "../../../../../shared/services/constants.service";


@Component({
  selector: 'app-lesson-content-item-audio',
  templateUrl: './lesson-content-item-audio.component.html',
  styleUrls: ['./lesson-content-item-audio.component.scss'],
  imports: [
    NgIf,
    PreloaderComponent,
    FormsModule,
    AudioPlayerComponent
  ],
  standalone: true
})
export class LessonContentItemAudioComponent implements OnInit, OnChanges{
  @Input() EditMode = false;
  @Input() IsSelected = false;

  @Output() OnChangedFile = new EventEmitter<any>();

  @Input() ItemUid: string = '';
  @Input() ItemType: string = '';
  @Input() ItemData: any = null;

  @Input() buttonName = '';
  buttonNameInit = '';

  NewFile:any = null;
  NewFileUrl:any = null;

  IsPlay = false;

  testLoading = false;

  src='';

  isLoading = true;
  isLoadingAudio = false;
  isDownload = false;


  constructor(
      public loadingService: LoadingService,
      private educationService: EducationService,
      private eventsService: EventsService,
      private sanitizer: DomSanitizer,
      private constantsService:ConstantsService
  ) {}

  ngOnInit(): void {
    this.isLoading = false;
    this.buttonNameInit = this.buttonName;
  }

  get hasChanged() {
    return (this.NewFile!==null && this.NewFile != this.ItemData) || this.buttonName!==this.buttonNameInit
  };


  ChangeItemFile(files: any) {
    let size: number = +(files.files.item(0)['size'])
    if(size <= this.constantsService.FileMaxSize) {
      this.NewFile = files.files.item(0);
      this.NewFileUrl = this.sanitizer.bypassSecurityTrustUrl(
          URL.createObjectURL(this.NewFile));
      this.IsPlay = false;
      this.eventsService.success('Файл успешно прикреплён')
    } else{
      this.eventsService.throwError(`Размер аудио не должен превышать ${this.constantsService.FileMaxSizeText}!`)
    }
  }

  GetAudioFile(){
    this.isLoadingAudio = true;
    this.educationService.GetAudio(this.ItemUid).subscribe((res)=>{
      this.src = res;
      this.IsPlay = true;
      this.isLoadingAudio = false;
    }, err=>{
      this.isLoadingAudio = false;
    })


  }

  Save(){
      const form = new FormData();
      if(this.NewFile){
        form.append('file', this.NewFile);
      }
      form.append('buttonName', String(this.buttonNameInit?this.buttonNameInit:""));
      this.isLoadingAudio = true;
      this.educationService.EditLessonContentData(this.ItemUid, form)
          .subscribe(
              (res) => {
                //console.log("item = ", res);
                  if(this.NewFile){
                      this.ItemData = `/v1/lesson/template/data/${this.ItemUid}`;
                  }
                  this.OnChangedFile.emit({
                      data:this.ItemData,
                      buttonName: this.buttonNameInit
                  });
                this.buttonName = this.buttonNameInit;
                this.isLoadingAudio = false;
                this.IsPlay = false;
                this.NewFile = null;
                this.NewFileUrl = null;
              }, (err) => {
                 this.isLoadingAudio = false;
              }
          )



  }

  Play(event:any){
    event.stopPropagation();
    if(this.ItemData){
      this.GetAudioFile();
    } else{
      this.IsPlay = true;
    }

  }

  ToText(event:any){
      event.stopPropagation();
      this.IsPlay = false;

  }

    ngOnChanges(changes: SimpleChanges): void {
        //this.buttonNameInit = this.buttonName;
    }


}
