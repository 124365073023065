import {Component, Injectable, Input, OnDestroy, OnInit, ViewChild, HostListener} from '@angular/core';
import {catchError, EMPTY, Observable, of, switchMap} from "rxjs";
import {tap} from "rxjs/operators";
import {ActivatedRoute, ActivatedRouteSnapshot, Router, RouterLink, RouterStateSnapshot} from "@angular/router";
import { CreateLessonInfoComponent } from './create-lesson-info/create-lesson-info.component';
import { TestConstructorComponent } from '../test-constructor/test-constructor.component';
import {NgClass, NgIf} from "@angular/common";
import {LoadingService} from "../../../../shared/services/loading.service";
import {EducationService} from "../../../../shared/services/education.service";
import {LessonContentComponent} from "../../lesson-content/lesson-content.component";
import {LyDialog} from "@alyle/ui/dialog";
import {LyOverlay} from "@alyle/ui";

export type LessonTabs = 'info' | 'content' | 'tasks';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
    needConfirm: () => boolean;
}

@Component({
  selector: 'app-create-lesson',
  templateUrl: './create-lesson.component.html',
  styleUrls: ['./create-lesson.component.scss'],
  imports: [
    TestConstructorComponent,
    LessonContentComponent,
    CreateLessonInfoComponent,
    NgClass,
    RouterLink,
    NgIf
  ],
  standalone: true,
  providers:[
    {provide:LyDialog},
    {provide:LyOverlay},

  ]
})
export class CreateLessonComponent implements OnInit, ComponentCanDeactivate {




    @ViewChild('LessonInfoBlock') LessonInfoBlock: CreateLessonInfoComponent | undefined;
    @ViewChild('LessonContentBlock') LessonContentBlock: LessonContentComponent | undefined;
    @ViewChild('LessonTasksBlock') LessonTasksBlock: TestConstructorComponent | undefined;

    CourseId = 0;
    blockId = 0;
    LessonId = 0;

    Value:any = false;

    currentPage: LessonTabs = 'info';

    constructor(
        private activatedRoute:ActivatedRoute,
        private router: Router,
        private educationService: EducationService,
        public loadingService: LoadingService,
        ) {
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.haveUnsaved();
    }

    ngOnInit(): void {
        this.InitLessonData();
    }


    InitLessonData(){
        this.activatedRoute.params
            .pipe(
                switchMap((res:any)=>{
                    this.LessonId = +res['id'];
                    if(this.LessonId !==0){
                        return this.activatedRoute.queryParams
                            .pipe(
                                tap((res)=>{
                                    this.GetQueryParams(res)
                                }),
                                switchMap(()=>{
                                    return this.GetCourseItem()
                                }),
                                switchMap(()=>{
                                    return this.GetBlockItem();
                                }),



                            )
                    }
                    else{
                        return this.activatedRoute.queryParams
                            .pipe(
                                tap((res)=>{
                                    this.GetQueryParams(res)
                                }),
                                switchMap(()=>{
                                    return this.GetCourseItem()
                                }),
                                switchMap(()=>{
                                    return this.GetBlockItem();
                                })


                            )
                    }

                })
            ).subscribe()
    }

    GetQueryParams(res:any){
        this.CourseId = +res['courseID'];
        this.blockId = +res['blockId'];
        if(!this.blockId || !this.CourseId ){
            this.router.navigate(['base','education','course',this.CourseId]);
        }
    }

    GetCourseItem(){
        return this.educationService.GetCourseItem(this.CourseId,this.loadingService.StatesNames.GET_LESSON)
            .pipe(
                catchError(()=>{
                    this.router.navigate(['base','education']);
                    return EMPTY;
                })
            )
    }

    GetBlockItem(){
        return this.educationService.GetBlockItem(this.blockId,this.loadingService.StatesNames.GET_LESSON)
            .pipe(
                catchError(()=>{
                    this.router.navigate(['base','education']);
                    return EMPTY;
                })
            )
    }



    changeTab(newTab: LessonTabs) {
        if(this.haveUnsaved() && !this.needConfirm()) return;
        this.currentPage = newTab;
    }

    needConfirm() {
        return confirm("Вы уверены, что хотите выйти без сохранения?");
    }

    haveUnsaved() {
        if (this.LessonInfoBlock && this.LessonInfoBlock.haveUnsaved()) {
            return true
        }
        if (this.LessonContentBlock && this.LessonContentBlock.haveUnsaved()) {
            return true
        }
        if (this.LessonTasksBlock && this.LessonTasksBlock.haveUnsaved()) {
            return true
        }
        return false;
    }

}

//TODO

@Injectable()
export class PendingChangesGuard  {
    canDeactivate(
        component: ComponentCanDeactivate,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): boolean | Observable<boolean> {
        return nextState.url == '/auth/login' ? true : component.canDeactivate() ? true : component.needConfirm();
    }
}
