export function isValidLink(s: string) {
  return isValidYoutube(s) || isValidRutube(s) || {status: false}
}

function isValidYoutube(s: string) {
  const m = s.match(/^https:\/\/(www\.)?(youtube\.com\/)(watch\?v=|shorts\/)([^\/\s&]*)(\&.+=.+)?(\/)?$/);
  if(m == null) return null;
  return {
    status: true,
    originUrl: m[2],
    videoType: m[3],
    id: m[4],
    password: null,
    origin: 'YOUTUBE',
  }
}
function isValidRutube(s: string) {
  const m = s.match(/^https:\/\/(www\.)?(rutube\.ru\/)(video\/|shorts\/)(private\/)?([^\/\s]*)(\/\?p=(.+))?(\/)?$/);
  if(m == null) return { status: false };
  return {
    status: true,
    originUrl: m[2],
    videoType: m[3],
    id: m[5],
    password: m[7],
    origin: 'RUTUBE',
  }
}
/*let res1 = console.log(isValidLink("https://www.youtube.com/shorts/yhGIxIkaHCU"));
let res2 = console.log(isValidLink("https://www.youtube.com/watch?v=fbp5NTw1r2U"));
let res3 = console.log(isValidLink("https://rutube.ru/shorts/513393492a1fdf70391b572881828dd4/"));
let res4 = console.log(isValidLink("https://rutube.ru/video/adc021d42be2d5d0fdf78e2423fc631c/"));
let res5 = console.log(isValidLink("https://rutu2be.ru/video/adc021d42be2d5d0fdf78e2423fc631c/"));*/
