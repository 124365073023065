<a
    [routerLink]="'/base/education/course/'+CourseId"
    class="go-back"
>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5825 6.59249H3.4125L8.2925 1.71249C8.6825 1.32249 8.6825 0.682486 8.2925 0.292486C8.10567 0.105233 7.85202 0 7.5875 0C7.32298 0 7.06933 0.105233 6.8825 0.292486L0.2925 6.88249C-0.0975 7.27249 -0.0975 7.90249 0.2925 8.29249L6.8825 14.8825C7.2725 15.2725 7.9025 15.2725 8.2925 14.8825C8.6825 14.4925 8.6825 13.8625 8.2925 13.4725L3.4125 8.59249H14.5825C15.1325 8.59249 15.5825 8.14249 15.5825 7.59249C15.5825 7.04249 15.1325 6.59249 14.5825 6.59249Z" fill="#2D3C59"/>
    </svg>
    Вернуться назад
</a>

<ul class="lesson-nav" *ngIf="LessonId">
    <li (click)="changeTab('info')" [ngClass]="{'selected': currentPage == 'info'}">Информация</li>
    <li (click)="changeTab('content')" [ngClass]="{'selected': currentPage == 'content'}">Контент</li>
    <li (click)="changeTab('tasks')" [ngClass]="{'selected': currentPage == 'tasks'}">Задания</li>
</ul>

<app-create-lesson-info
    *ngIf="currentPage == 'info'"
    #LessonInfoBlock
    [blockId]="blockId"
    [CourseId]="CourseId"
    [LessonId]="LessonId"
    (OnUpdateLessonId)="LessonId = $event['LessonId']"
></app-create-lesson-info>


<div class="lesson-content-wrap" *ngIf="LessonId && currentPage == 'content'">
    <app-lesson-content
        #LessonContentBlock
        [LessonId]="LessonId"
        [EditMode]="true"
    ></app-lesson-content>
</div>

<div class="test-wrap" *ngIf="LessonId && currentPage == 'tasks'">
    <app-test-constructor
        #LessonTasksBlock
        [LessonId]="LessonId"
    ></app-test-constructor>
</div>
