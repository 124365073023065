import {Injectable} from "@angular/core";
import {HTTPService} from "./main.service";
import {LoadingService} from "./loading.service";
import {switchMap} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {DownloadService} from "./download.service";
import {map} from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EducationService {


  constructor(private mainService: HTTPService, private loadingService: LoadingService, private http: HttpClient, private downloadService: DownloadService) {
  }


  GetCourses(folderId: any = null,active:any = null, type: any = this.loadingService.StatesNames.GET_COURSES) {
    //Добавить параметр для фильтрации по папке
    let query = '?';
    query = folderId?query+`folderId=${folderId}`:query;
    query = active?query+`active=${active}`:query;
    return this.mainService.SendGet('/course'+query, type)
  }

  GetCoursesShort(published:any = null,type: any = this.loadingService.StatesNames.GET_COURSES) {
    const query = published!==null?`?published=${published}`:'';
    return this.mainService.SendGet(`/course/short-list`+query, type)
  }

  GetLessonsShort(courseId: any, blockId: any, type: any = this.loadingService.StatesNames.GET_LESSONS) {
    return this.mainService.SendGet(`/lesson/short-list?courseId=${courseId}&blockId=${blockId}`, type)
  }

  GetBlocksShort(courseId: any, type: any = this.loadingService.StatesNames.GET_BLOCKS) {
    return this.mainService.SendGet(`/block/short-list?courseId=${courseId}`, type)
  }

  GetCourseItem(id: number, type: any = this.loadingService.StatesNames.GET_COURSES_ITEM) {
    return this.mainService.SendGet(`/course/${id}`, type)
  }

  CreateCourse(data: any, type = this.loadingService.StatesNames.CREATE_COURSE) {
    return this.mainService.SendPost(`/company/course`, data, type)
  }

  EditCourse(id: number, data: any, type = this.loadingService.StatesNames.CREATE_COURSE) {
    return this.mainService.SendPatch(`/company/course/${id}`, data, type)
  }

  GetCourseImage(id: number, type: string, loading = this.loadingService.StatesNames.COURSE_AVATAR) {
    return this.mainService.RequestHandler(() => {
      return this.http
        .get(`${this.mainService.ServerURL}` + `/course/${type}/${id}`, {
          headers: this.mainService.headers,
          responseType: "blob" as "json",
          observe: "response",
        })
        .pipe(
          switchMap(
            (res: any) => {
              return this.downloadService.saveResponse2Img(res)
            })
        );
    }, this.loadingService.StatesNames.COURSE_AVATAR);


  }

  getWord(number: number, one: string, two: string, five: string) {
    let n = number;
    n %= 100;
    if (n >= 5 && n <= 20) {
      return five;
    }
    n %= 10;
    if (n === 1) {
      return one;
    }
    if (n >= 2 && n <= 4) {
      return two;
    }
    return five;
  }


  //Блоки

  GetBlocks(id: number, type = this.loadingService.StatesNames.GET_BLOCKS) {
    return this.mainService.SendGet(`/block?courseId=${id}`, type)
  }

  GetBlockItem(id: number, type = this.loadingService.StatesNames.GET_BLOCKS) {
    return this.mainService.SendGet(`/block/${id}`, type)
  }

  CreateBlock(data: any, type = this.loadingService.StatesNames.CREATE_BLOCK) {
    return this.mainService.SendPost('/company/block', data, type)
  }

  EditBlock(id: number, data: any, type = this.loadingService.StatesNames.CREATE_BLOCK) {
    return this.mainService.SendPatch(`/company/block/${id}`, data, type)
  }

  DeleteBlocks(courseId: number, blockIds: number[]) {
    return this.mainService.SendDelete(`/company/blocks`, {courseId, blockIds})
  }

  //Уроки
  CreateLesson(data: any, type = this.loadingService.StatesNames.CREATE_LESSON) {
    return this.mainService.SendPost('/company/lesson', data, type)

  }

  EditLesson(id: number, data: any, type = this.loadingService.StatesNames.CREATE_LESSON) {
    return this.mainService.SendPatch(`/company/lesson/${id}`, data, type)
  }

  GetLessonsList(courseId: number, blockId: number | null = null, type: any = this.loadingService.StatesNames.GET_LESSONS, limit: number | null = null, page: number | null = null,) {
    let str = `/lesson?courseId=${courseId}`;
    if (blockId) {
      str = str + `&blockId=${blockId}`;
    }
    return this.mainService.SendGet(str, type)
  }

  GetLessonImage(id: number, type: string, loading = this.loadingService.StatesNames.LESSON_ICON) {
    return this.mainService.RequestHandler(() => {
      return this.http
        .get(`${this.mainService.ServerURL}` + `/lesson/${type}/${id}`, {
          headers: this.mainService.headers,
          responseType: "blob" as "json",
          observe: "response",
        })
        .pipe(
          switchMap(
            (res: any) => {
              return this.downloadService.saveResponse2Img(res)
            })
        );
    }, this.loadingService.StatesNames.LESSON_ICON);


  }

  GetLessonItem(id: number, type = this.loadingService.StatesNames.GET_LESSON) {
    return this.mainService.SendGet(`/lesson/${id}`, type)
  }

  GetTests(lessonId: number, type = this.loadingService.StatesNames.GET_TESTS) {
    return this.mainService.SendGet(`/lesson/${lessonId}/tests`, type)
  }

  EditTests(lessonId: number, data: any, type = this.loadingService.StatesNames.EDIT_TESTS) {
    return this.mainService.SendPatch(`/company/lesson/${lessonId}/tests`, data, type)
  }

  GetTestFile(id: any) {
    return this.mainService.RequestHandler(() => {
      return this.http
        .get(`${this.mainService.ServerURL}` + `/lesson/get-file/${id}`, {
          headers: this.mainService.headers,
          responseType: "blob" as "json",
          observe: "response",
        })
        .pipe(
          map(
            (res: any) => {
              return this.downloadService.getResponse2File(res)
            })
        );
    }, this.loadingService.StatesNames.TEST_FILE);


  }

  UploadTestFile(data: any) {
    return this.mainService.SendPost('/lesson/upload-file', data, this.loadingService.StatesNames.UPLOAD_TEST_FILE);
  }

  UpdateTriggers(data: any, type = this.loadingService.StatesNames.UPDATE_TRIGGERS) {
    return this.mainService.SendPost(`/company/lesson/update-open-conditions`, data, type)
  }

  UpdateOrders(courseId: number, data: any, type = this.loadingService.StatesNames.UPDATE_ORDERS) {
    return this.mainService.SendPatch(`/company/lesson/reorder/${courseId}`, data, type)
  }

  DeleteLessons(courseId: number, lessons: number[]) {
    return this.mainService.SendDelete(`/company/lesson`, {courseId, lessons})
  }

  CopyLessons(courseFrom: number, blockTo: number, lessons: number[]) {
    return this.mainService.SendPatch(`/company/lesson/copy/${courseFrom}`, {blockTo, lessons}, this.loadingService.StatesNames.CONFIRM)
  }


  SendTest(lessonId: number, data: any, type = this.loadingService.StatesNames.SEND_TESTS) {
    return this.mainService.SendPost(`/lesson/${lessonId}/send`, data, type)

  }


  GetLessonContent(lessonId: number, type = this.loadingService.StatesNames.GET_LESSON_CONTENT) {
    return this.mainService.SendGet(`/lesson/${lessonId}/template`, type)
  }

  EditLessonContent(lessonId: number, data: any, type = this.loadingService.StatesNames.EDIT_LESSON_CONTENT) {
    return this.mainService.SendPatch(`/company/lesson/${lessonId}/template`, data, type)
  }

  GetLessonContentData(uid: string) {
    return this.mainService.getFile(`/lesson/template/data/${uid}`);
  }

  GetAudio(uid: any) {
    return this.mainService.RequestHandler(() => {
      return this.http
        .get(`${this.mainService.ServerURL}` + `/lesson/template/data/${uid}`, {
          headers: this.mainService.headers,
          responseType: "blob" as "json",
          observe: "response",
        })
        .pipe(
          switchMap(
            (res: any) => {
              return this.downloadService.saveResponse2Img(res)
            })
        );
    }, this.loadingService.StatesNames.GET_AUDIO);


  }

  GetLessonContentImage(id: string) {
    return this.mainService.RequestHandler(() => {
      return this.http
        .get(`${this.mainService.ServerURL}` + `/lesson/template/data/${id}`, {
          headers: this.mainService.headers,
          responseType: "blob" as "json",
          observe: "response",
        })
        .pipe(
          switchMap(
            (res: any) => {
              return this.downloadService.saveResponse2Img(res)
            })
        );
    }, this.loadingService.StatesNames.NEWS_IMG);
  }

  EditLessonContentData(uid: string, data: any) {
    return this.mainService.SendPatch(`/company/lesson/template/${uid}`, data)
  }

  //Отредактировать
  EditLessonProgressContentData(uid: string, data: any) {
    return this.mainService.SendPatchProgressBar(`/company/lesson/template/${uid}`, data)
  }

  // GetSentList(status: any = '', courseId: any = '', profileId: any = '') {
  //   const str = status ? `?status=${status}` : '';
  //   const course = courseId ? `&courseId=${courseId}` : '';
  //   const profile = profileId ? `&status=${profileId}` : '';
  //   return this.mainService.SendGet(`/lesson/sent` + str + course + profile, this.loadingService.StatesNames.GET_SENT_LIST);
  // }
  GetSentList(status: any = '', courseId: any = '', profileId: any = '') {
    let queryString = '';

    if (status || courseId || profileId) {
      const params = [];

      if (status) {
        params.push(`status=${status}`);
      }
      if (courseId) {
        params.push(`courseId=${courseId}`);
      }
      if (profileId) {
        params.push(`profileId=${profileId}`);
      }

      queryString = '?' + params.join('&');
    }

    return this.mainService.SendGet(`/lesson/sent${queryString}`, this.loadingService.StatesNames.GET_SENT_LIST);
  }


  GetSentItem(lessonId: number, profileId: any, type = this.loadingService.StatesNames.GET_SENT_ITEM) {
    const str = profileId ? `?profileId=${profileId}` : '';
    return this.mainService.SendGet(`/lesson/get-sent/${lessonId}` + str, type);
  }

  CheckLesson(lessonId: number, profileId: number, data: any, type = this.loadingService.StatesNames.CHECK_LESSON) {
    return this.mainService.SendPatch(`/company/lesson/${lessonId}/approve/${profileId}`, data, type);
  }

  RestartLesson(lessonId: number, type = this.loadingService.StatesNames.RESTART_LESSON) {
    return this.mainService.SendPatch(`/lesson/${lessonId}/restart`, {}, type);
  }

  CloneCourse(id: number, name: string, type = this.loadingService.StatesNames.CLONE_COURSE) {
    return this.mainService.SendPut(`/company/course/clone/${id}?name=${name}`, {});
  }

  SearchLesson(courseId: number, search: string) {
    return this.mainService.SendGet(`/course/${courseId}/lesson/search?search=${search}`)
  }

  GetStatsCourses() {
    return this.mainService.SendGet('/company/students-statistics/list-course-statistics',
      this.loadingService.StatesNames.GET_STATS_COURSES)
  }

  GetStatsCourseCard() {
    return this.mainService.SendGet('/company/students-statistics/course-students-stats',
      this.loadingService.StatesNames.GET_STATS_COURSES_CARD)
  }

  GetStatsCoursesStudents(courseId: any) {
    return this.mainService.SendGet(`/company/students-statistics/list-students-course/${courseId}`,
      this.loadingService.StatesNames.GET_STATS_COURSES_STUDENTS)
  }

  GetStatsCoursesLessons(courseId: any, blockId: any) {
    return this.mainService.SendGet(`/company/students-statistics/course-lessons-stats/${courseId}?blockId=${blockId}`,
      this.loadingService.StatesNames.GET_STATS_COURSES_LESSONS)
  }
}
