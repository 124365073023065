<div class="test-wrap">
    <p>Введите вопрос, на который хотите получить развернутый ответ</p>
    <div class="content">
      <ckeditor
        [editor]="Editor1"
        [config]="config"
        [(ngModel)]="question"
        (ngModelChange)="ChangeQuestionText()"
      ></ckeditor>

        <a class="delete" (click)="DeleteTest()">
            <img src="assets/icons/blocks/delete.svg">
        </a>
    </div>
  <div class="input-wrap--file">
    <p>Файл</p>
    <app-preloader
      *ngIf="
            loadingService.States[
              loadingService.getIdFromArrayStateByType(
                loadingService.StatesNames.UPLOAD_TEST_FILE
              )
            ].value;else fileUpload"></app-preloader>
    <ng-template #fileUpload>
      <div class="file-wrapper--button"
         *ngIf="!FileName"
         (click)="$event.stopPropagation()">
      <input
        [id]="'file'+id"
        type="file"
        (change)="UploadFile($event.target)"
      >
      <label [for]="'file'+id" class="label-file">
        <div class="label-content">
          <img src="assets/icons/download.svg"> Загрузить файл
        </div>
      </label>
    </div>
    </ng-template>
    <app-preloader
      *ngIf="
            DownloadId===id && loadingService.States[
              loadingService.getIdFromArrayStateByType(
                loadingService.StatesNames.TEST_FILE
              )
            ].value;else fileDownload"></app-preloader>
    <ng-template #fileDownload>
      <div class="file-preview" *ngIf="FileName">
        <button type="button" class="btn-custom  btn-active"
                (click)="DownloadFile()">
          {{FileName||'Скачать файл'}}
        </button>
        <a type="button" (click)="DeleteFile()"><img src="assets/icons/delete.svg"></a>
      </div>
    </ng-template>
  </div>


</div>

