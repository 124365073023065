import { DOCUMENT } from '@angular/common';
import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { timer } from 'rxjs';
import { lock, unlock } from 'tua-body-scroll-lock';
import { Modal } from './modal-options';

@Component({
  template: `<ng-template #modalContainer></ng-template> `,
})
export class ModalContainerComponent implements OnInit, OnDestroy {
  @ViewChild('modalContainer', { read: ViewContainerRef })
  private modalContainer!: ViewContainerRef;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private componentFactoryResolver: ComponentFactoryResolver,
    private renderer: Renderer2,
  ) {}
  ngOnInit(): void {
    timer(0).subscribe(() => {
      const elementOne = document.querySelector('#allscroll')
      lock();
      unlock()
      this.renderer.addClass(document.body, 'overflow-hidden');
    });
  }

  ngOnDestroy(): void {
    if (this.document.getElementsByTagName('app-modal').length < 2) {
      this.renderer.removeClass(document.body, 'overflow-hidden');
      unlock();
    }
  }

  createModal<T extends Modal>(component: Type<T>): ComponentRef<T> {
    this.modalContainer.clear();

    const factory: ComponentFactory<T> =
      this.componentFactoryResolver.resolveComponentFactory(component);

    return this.modalContainer.createComponent(factory, 0);
  }
}
