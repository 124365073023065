import {Injectable} from "@angular/core";
import {LoadingService} from "./loading.service";
import {HTTPService} from "./main.service";
import {switchMap} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {DownloadService} from "./download.service";

@Injectable({
    providedIn: "root",
})
export class PartnersService {
    constructor(private loadingService: LoadingService,
                private mainService: HTTPService,
                private http: HttpClient,
                private downloadService: DownloadService
                ) {
    }

    GetPartnersList(){
        return this.mainService.SendGet('/partner/',this.loadingService.StatesNames.GET_PARTNERS_LIST)
    }

    GetPartnersShortList(){
        return this.mainService.SendGet('/partner/short-list',this.loadingService.StatesNames.GET_PARTNERS_SHORT_LIST)
    }

    GetPartnerImage(id:number){
        return this.mainService.RequestHandler(() => {
            return this.http
                .get(`${this.mainService.ServerURL}`+`/partner/image/${id}`, {
                    headers: this.mainService.headers,
                    responseType: "blob" as "json",
                    observe: "response",
                })
                .pipe(
                    switchMap(
                        (res:any)=>{
                            return this.downloadService.saveResponse2Img(res)
                        })
                );
        }, this.loadingService.StatesNames.GET_PARTNER_IMAGE);
    }

    GetPartnerContact(id:number){
        return this.mainService.SendGet(`/partner/contact/${id}`,this.loadingService.StatesNames.GET_PARTNER_CONTACT)

    }

    GetPartner(id:number){
        return this.mainService.SendGet(`/partner/${id}`,this.loadingService.StatesNames.GET_PARTNER_CONTACT)

    }

    CreatePartner(data:any){
        return this.mainService.SendPost('/partner/',data,this.loadingService.StatesNames.ADD_PARTNER);

    }

    EditPartner(id:number,data:any,loading = this.loadingService.StatesNames.DELETE_PARTNER){
        return this.mainService.SendPatch(`/partner/${id}`,data,this.loadingService.StatesNames.ADD_PARTNER);

    }

    ShowSystemPartner(id:number){
        return this.mainService.SendPatch(`/partner/show/${id}`,{});

    }
}
