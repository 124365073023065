import { Component, Input, OnInit } from '@angular/core';
import {EducationService} from "../../services/education.service";

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
  standalone:true,
})
export class AudioPlayerComponent implements OnInit {
  @Input() src:string = '';

  audioLink = ''
  hasError = false;


  ngOnInit() {
  }



  onError(event: any) {
        window.open(this.src, '_blank')!.focus();
  }

  constructor(){

  }

}
